import React, { useState, useEffect } from 'react'
import { getRatings } from '../../firebase/DbConnection'
import Explanation from '../BaseComponent/Explanation'


const DisplayRatingsForProfile = ({ uid, genderString }) => {
    const [Ratings, setRatings] = useState({})

    useEffect(() => getRatings(uid, genderString, (ratings) => { setRatings(ratings) }), [])
    console.log("DisplayRatingsForProfile>Ratings:  ", Ratings)
    return (<div>
        <h2>Ratings</h2>

        <div className="line">
            <div className="line-head">
                Level:
            </div>
            <div className="line-content">
                <Explanation text="This is the ranking of the member: It reflects a weighted average that includes the ratings he received from the people he worked with as well as seniority, etc." />
                {Ratings.rating}
            </div>
        </div>

        {genderString === "asFreelancer" &&
            <div className="line">
                <div className="line-head">
                    Jobs that the freelancer failed to submit:
                    </div>
                <div className="line-content">
                    <Explanation text="Intention - work that ended with no submission, partial submission without employer consent or conflict" />
                    {Ratings.nonWork}
                </div>
            </div>
        }

        <h2>Recommendations:</h2>

        <div className="recommendations-body">
            {Ratings.recommendations && Ratings.recommendations.map((recommendation, i) => {
                // Don't show ratings from last month
                if (recommendation.ReportingDate && (recommendation.ReportingDate > (new Date().getTime() - 30 * 24 * 60 * 60000))) return null

                return (
                    <div key={i} className="recommendation-item">
                        <div className="item-count">{i + 1}) </div>
                        <div>
                            Experience description: {recommendation["verbal description"] ? recommendation["verbal description"] : "No verbal description was entered - for this work"}
                            {recommendation.Communication && <div>Communication:{recommendation.Communication}</div>}

                            {recommendation.Accuracy && <div>Accuracy:{recommendation.Accuracy}</div>}
                            {recommendation.Courtesy && <div>Courtesy:{recommendation.Courtesy}</div>}
                            {recommendation.Professionalism && <div>Professionalism:{recommendation.Professionalism}</div>}
                            {recommendation.Punctuality && <div>Punctuality:{recommendation.Punctuality}</div>}

                            {recommendation["Wage Fairness"] && <div>Wage Fairness:{recommendation["Wage Fairness"]}</div>}
                            {recommendation["changes and additions"] && <div>changes and additions:{recommendation["changes and additions"]}</div>}
                            {recommendation.wording && <div>wording:{recommendation.wording}</div>}

                            {recommendation["general grade"] && <div>general grade:{recommendation["general grade"]}</div>}
                        </div>

                    </div>
                )
            })}
        </div>

    </div>)

}

export default DisplayRatingsForProfile