import { firebase, googleAuthProvider } from '../firebase/firebase';

// export const login = (uid) => ({
//   type: 'LOGIN',
//   uid
// });

// export const startLogin = () => {
//   return () => {
//     return firebase.auth().signInWithPopup(googleAuthProvider);
//   };
// };
export const startLogin = () => {
  firebase.auth().signInWithPopup(googleAuthProvider);
  
 
};

// export const logout = () => ({
//   type: 'LOGOUT'
// });

export const startLogout = () => {
// console.log("startLogout1")
//   return () => {
//     console.log("startLogout2")
//     return 
    firebase.auth().signOut();
  // };
};
