import React, { useContext, useState } from 'react';
import ToggleSwitch from '../BaseComponent/ToggleSwitch';
import userContext from '../../context/user-context'
import { UpdateOccupationalGender } from '../../firebase/DbConnection'

const ChooseGender = () => {
    const { user, dispatch } = useContext(userContext);
    // console.log("ChooseGender>user.OccupationalGender:  ",user.OccupationalGender)
    // const isFreelancer = user.OccupationalGender === "freelancer"
    const isFreelancer = user.OccupationalGender != "employer"
    console.log("ChooseGender>isFreelancer:  ",isFreelancer)



    const onChange = () => {

        const Cgender = user.OccupationalGender === "employer" ? "freelancer" : "employer"

        dispatch({ type: 'ADD_VALUE_TO_USER', name: "OccupationalGender", value: Cgender })

        console.log(Cgender)
        UpdateOccupationalGender(user, Cgender)

    }
    const ic = true

    return (
        <div className="chooseGenderBox">
            {/* <h5>choose your occupational definition:</h5> */}
            <div className="choose-action">
                employer
                <div className="toggle">
                    <ToggleSwitch checked={isFreelancer} onChange={onChange} />
                </div>
                freelancer
            </div>
        </div>
    )
}

export default ChooseGender;