import * as firebase from 'firebase';
import firebaseConfig from './firebaseConfigDev'


const config = firebaseConfig
  
 
    

 
firebase.initializeApp(config);

const database = firebase.database();
// firebase.database().ref().set({d:'ddddddddddddd',s:'sssssssssssss'})
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { firebase, googleAuthProvider, database as default };
// export { firebase, googleAuthProvider };
