import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import RatingInstructionPopup from '../support/InstructionPopup/RatingInstructionPopup'

const EmployerCarousel = () => {


  return (
     <div className="carousel-wrapper" style={{ 'background-image': "url(/images/hero-slider.png)" }}>
      <Carousel>
        <Carousel.Item>
          <div className="carouselItem"><br />
                No more negotiating hours -<br />
                To find out that - the freelancer is already occupied ...<br />
                No more exhausting employee search...<br /><br />
            <h1>So, how does it work?</h1>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1>How does it work?</h1> <br /><br />
                You define what you want to be build for you-<br />
                And sends!<br />
                And the relevant freelancer will already do it for you
            </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h2> And who said I would get a professional freelancer? </h2> <br />
            <h4>Freelancers are ranked in the system by levels<br />
                    The lower the rating, the higher the risk, but the lower the expected salary<br />
                    The higher the rating, the lower the risk, but the higher the expected salary<br />
                    We recommend over 5<br /></h4>
                For more details click here:
                <RatingInstructionPopup />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem">
            <h1> What is important to keep in mind - for successful work?</h1>
                Define exactly what you want and clearly!<br />
                The more defined and understood - the more freelancers want to take the job and you get exactly what you
                wanted<br /><br />
                Give a fair payment for the job - Of course it greatly affects the motivation of freelancers to take the
                job<br /><br />
                Set the time according to your intentions<br />
                And the level depending on the level of risk you want to take
            </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1> So how to start?</h1> <br />
                Click `{">>>"}` Broadcast a new job<br />
                Define what you want to be done<br />
                Send ...<br />
                And of course - don't forget to rate the freelancer for the work he did
            </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )

}

export default EmployerCarousel