import React from 'react'
import Carousel from 'react-bootstrap/Carousel'


const FreelancerCarousel = () => {
  return (
    <div className="carousel-wrapper" style={{ 'background-image': "url(/images/hero-slider.png)" }}>
      <Carousel>
        <Carousel.Item>
          <div className="carouselItem"><br />

            No more negotiating hours -<br />
            To find out that - someone else got the job ...<br /><br />
            <h1>So, how does it work?</h1>

          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1>How does it work?</h1> <br />
            I saw a job I could do - I took!<br />
            Now it's mine! <br /> I only have to perform!
        </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            The main thing is trust and honesty<br /><br />
            <h1>Only jobs I'm sure I can do - I'm taking!</h1><br />
            This is my criterion for future work!
        </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1> What is important to check before taking a job?</h1><br />
            That I know how to do whatever it takes<br />
            I understand exactly what the employer wants<br />
            That the set time is right for me<br />
            That the amount offered is right for me
        </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1> Why is it important to check before?</h1><br />
            If conditions do not fit -<br />
            I will not be satisfied<br />
            The employer will not be satisfied<br />
            My rating will be low<br />
            And I can't access the better jobs
        </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />

            <h1> And if I get a higher rating?</h1><br />
            I can take the more equal jobs<br />
            And of course - earn more ...
        </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem">
            So how do you get started?<br />
            Go to `{">>>"}` See job offers<br /><br />
            <h1>Choose the job you like most</h1><br />
            Click the `{">>>"}` Apply for this job button<br />
            <h1>And you started!</h1>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>)

}

export default FreelancerCarousel