import React, {useContext} from 'react'
import userContext from '../../../context/user-context'
import Popup from '../../Containers/Popup'
import {architectFinishReporting} from '../../../firebase/DbConnection'
import strings from '../../../strings/navigator'


const FinishReporting=({item})=>{
    const {user}= useContext(userContext)

    const buttons=[{ButtonText:"Confirm completion",ButtonFunction:()=>{
        architectFinishReporting(user.uid,item)
    }}]
const body=<h2>{strings.finishedWork.body}</h2>

    return <Popup Title={strings.finishedWork.title} Buttons={buttons}  Body={body} OpenPopupButtonText="work was finished" />
}

export default FinishReporting