import React, {useContext} from 'react'
import userContext from '../../../context/user-context'
import Popup from '../../Containers/Popup'
import {DeleteArchitectComponent} from '../../../firebase/DbConnection'
import strings from '../../../strings/navigator'


const DeleteComponent=({item})=>{
const {user}= useContext(userContext)
console.log("DeleteComponent> item:  ",item)

return(
    <Popup 
    Title={"Delete component "+item.title} 
    Body={strings.deleteWork.body}
    CloseButtons={[{ButtonText:"Confirm deletion",ButtonFunction:()=>{DeleteArchitectComponent(user.uid,item)}}]}
    OpenPopupButtonText="delete"
    />
)
}

export default DeleteComponent