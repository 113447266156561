import React, {useContext} from 'react';
import {AcceptedTermsOfUse}  from '../../firebase/DbConnection'
import userContext from '../../context/user-context'
import strings from '../../strings/navigator'

const ApprovalButton=()=>{
   const {user, dispatch}= useContext(userContext)
   const acceptedTermsOfUse=()=>{
    AcceptedTermsOfUse( user, dispatch)
   }

    return <button onClick={acceptedTermsOfUse}>{strings.Approval.termsOfUse}</button>
}

export default ApprovalButton