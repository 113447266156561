import React, {useState, useContext} from 'react'
import userContext from '../../../context/user-context'
import Form from 'react-bootstrap/Form'
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Popup from '../../Containers/Popup'
import {saveSubcomponent} from '../../../firebase/DbConnection'
import ComponentMultiplier from '../../Containers/ComponentMultiplier'
// import EmailInput from '../../BaseComponent/EmailInput'
// import {SendEmails} from '../../../Management/Email'
import PermissionInput from '../../Containers/PermissionInput'
import strings from '../../../strings/navigator'

const DefineComponent=({item, isForUpdate=false})=>{
console.log("DefineSubcomponent,item:  ",item)

const {user}=useContext(userContext)

const [Description,setDescription]= useState(isForUpdate?item.Description:"")
const [Details,setDetails]= useState(isForUpdate?item.Details:"")
const [Title,setTitle]= useState(isForUpdate?item.title:"")
const [Requirements,setRequirements]= useState(isForUpdate?item.Requirements:"")
const [Conditions,setConditions]= useState(isForUpdate?item.Conditions:"")
const [ServiceProviders,setServiceProviders]= useState(isForUpdate?item.ServiceProviders:"")
const [ServiceRecipients,setServiceRecipients]= useState(isForUpdate?item.ServiceRecipients:"")
const [Links,setLinks]= useState(isForUpdate?item.Links:"")
const [PermissionEmails,setPermissionEmails]=useState(isForUpdate&&item.permissions?item.permissions.map((permission)=>{return permission.email}):[])
const [Permissions,setPermissions]=useState(isForUpdate&&item.permissions?item.permissions.map((permission)=>{return permission.permission}):[])
const PermissionsArray=["Manage", "edit"," add components"," edit and add components"]




const [PopupState,setPopupState]=useState(true)
// const Remote=useState(true)

console.log("DefineSubcomponent,user:  ",user)
const Reset=()=>{
  setTitle("")
  setDescription("")
  setDetails("")
  setRequirements("")
  setConditions("")
  setServiceProviders("")
  setServiceRecipients("")
  setLinks("")

  setPermissionEmails([])
  setPermissions([])

  setDate(moment().add(100, 'days')

  )
}

const saveComponent=()=>{
  if (Title.length<5){alert("The title should be at least 5 characters")}
else{
const  permissions=getPermissionsObjectsArray()
console.log("saveComponent>permissions:  ",permissions)
  const component={title:Title,
    Description,
    Details,
    Requirements,
    Conditions,
    ServiceProviders,
    ServiceRecipients,
    Links,
    Date:date.format('dddd MMMM Do YYYY HH:mm:ss:x Z'),
    permissions}
     saveSubcomponent(user.uid,item.spath,component,isForUpdate)
   if (PermissionEmails.length!==0){
    // SendEmails(PermissionEmails,) 
    alert("Edit permissions have not yet been set")}
  
    
    setPopupState(!PopupState)
    // Remote[1](false)
    setPopupState(false)
    !isForUpdate&&Reset()
}
}
// Permissions
const getPermissionsObjectsArray=()=>{
  const PermissionsObjectsArray=[]
  for (let i=0;i< PermissionEmails.length ;i++){
    console.log("getPermissionsObjectsArray>PermissionEmails[i]:  ",PermissionEmails[i])
    console.log("getPermissionsObjectsArray>permission:Permissions[i]:  ",Permissions[i])
if(Permissions[i]===undefined){Permissions[i]=-2}
const PermissionObject={email:PermissionEmails[i]   ,permission:Permissions[i]}
if (!(PermissionEmails[i]===undefined||PermissionEmails[i]===null||PermissionEmails[i].length<5)){
PermissionsObjectsArray.push(PermissionObject)}
  }
  return(PermissionsObjectsArray)
}
const setPermissionsSelect=(value,i)=>{
  console.log("DefineSubcomponent>setPermissionsSelec>value:  ",value)
  console.log("DefineSubcomponent>Permissions:  ",Permissions)
  const newArray =Permissions
  newArray[i]=value
  setPermissions(newArray)
}
const setEmailValue=(value,i)=>{
  console.log("DefineSubcomponent>setEmail>value:  ",value)
  console.log("DefineSubcomponent>setEmail>i:  ",i)

  const newArray=PermissionEmails
  newArray[i]=value
  console.log("DefineSubcomponent>setEmail>newArray[i]:  ",newArray[i])

  setPermissionEmails(newArray)
  console.log("DefineSubcomponent>setEmail>PermissionEmails:  ",PermissionEmails)
  
}

//end of Permissions

const onDataChange=(e)=>{
  
    switch (e.target.className){

        case ("Title form-control"):
        setTitle(e.target.value)
        break

        case ("Description form-control"):
        setDescription(e.target.value)
        break

        case ("Details form-control"):
        setDetails(e.target.value)
        break

        case ("Requirements form-control"):
        setRequirements(e.target.value)
        break

        case ("Conditions form-control"):
        setConditions(e.target.value)
        break

        case ("ServiceProviders form-control"):
        setServiceProviders(e.target.value)
        break

        case ("ServiceRecipients form-control"):
        setServiceRecipients(e.target.value)
        break

        case ("Links form-control"):
          setLinks(e.target.value)
          break



    }
}


// date picker
// const [date, setDate] = useState(moment().add(100, 'days'))
const [date, setDate] = useState(isForUpdate?moment(item.Date,"dddd MMMM Do YYYY HH:mm:ss:x Z"):moment().add(100, 'days'))
const [calendarFocused,setCalendarFocused]=useState(false)
const onFocusChange = ({ focused }) => {
    setCalendarFocused(focused)
  };
// date picker




    const bodyJsx=(<>
   
       
        
         <Form.Label>Title</Form.Label>

         <Form.Control 
          as="textarea" 
          className="Title"
          rows="1"
          placeholder={strings.addSonInTree.title}
          value={Title}
          onChange={onDataChange}
           />

          

<Form.Label>Description</Form.Label>
          <Form.Control 
          as="textarea" 
          className="Description"
          rows="5"
          placeholder={strings.addSonInTree.description}
          value={Description}
          onChange={onDataChange}
           />

<Form.Label>Details</Form.Label>
          <Form.Control 
          as="textarea" 
          className="Details"
          rows="6"
          placeholder={strings.addSonInTree.detail}
          value={Details}
          onChange={onDataChange}
           />

           
           <Form.Label>Execution Requirements</Form.Label>
          <Form.Control 
          as="textarea" 
          className="Requirements"
          rows="3"
          placeholder={strings.addSonInTree.executionRequirements}
          value={Requirements}
          onChange={onDataChange}
           />

           <Form.Label>Conditions</Form.Label>
          <Form.Control 
          as="textarea" 
          className="Conditions"
          rows="3"
          placeholder={strings.addSonInTree.conditions}
          value={Conditions}
          onChange={onDataChange}
           />

         <Form.Label>Service Providers</Form.Label>
          <Form.Control 
          as="textarea" 
          className="ServiceProviders"
          rows="3"
          placeholder={strings.addSonInTree.exists}
          value={ServiceProviders}
          onChange={onDataChange}
           />


         <Form.Label>Service Recipients</Form.Label>
          <Form.Control 
          as="textarea" 
          className="ServiceRecipients"
          rows="3"
          placeholder="Service Providers - Database and API - which must provide data for the rest of the components"
          value={ServiceRecipients}
          onChange={onDataChange}
           />


           <Form.Label>Links</Form.Label>
          <Form.Control 
          as="textarea" 
          className="Links"
          rows="2"
          placeholder={strings.addSonInTree.links}
          value={Links}
          onChange={onDataChange}
           />

           

<Form.Label>{strings.addSonInTree.date}</Form.Label> <br/>
<SingleDatePicker
            date={date}
            onDateChange={setDate}
            focused={calendarFocused}
            onFocusChange={onFocusChange}
            numberOfMonths={1}
            // isOutsideRange={() => false}
          /><br/>
          {/* I temporarily disabled - Edit permissions - because the permissions are still inactive */}
{/* <Form.Label>Edit permissions</Form.Label> <br/>
<ComponentMultiplier ComponentToMultiply={PermissionInput} 
ComponentProps={{EmailValue:PermissionEmails,setEmailValue,PermissionsSelect:Permissions,setPermissionsSelect,PermissionsArray}}/>
  */}
</>
    )

    return (<Popup Title={isForUpdate?"update task "+item.title:"Set up a new sub-task for "+item.title} Body={bodyJsx}  Buttons={[{ButtonText:"save task",ButtonFunction:saveComponent}]}  RemoteControl={[PopupState,setPopupState]} OpenPopupButtonText={isForUpdate?"Edit details":(<div>{strings.woodArchitecture.addSunButton}</div>)}/>)
}
const DefineSubcomponent=({item})=>{return DefineComponent({item, isForUpdate:false})}

const UpdateComponent=({item})=>{ return DefineComponent({item, isForUpdate:true})}
  
// const SubmitForExecution=({item})=>{ return DefineComponent({item, isForUpdate:true})}


export  {DefineSubcomponent as default, UpdateComponent}