import React, { useState, useEffect } from 'react'
import ExperienceSharingItem from './ExperienceSharingItem'
// import { getExperienceSharingArray } from '../../firebase/DbConnection'
import ExperienceSharing from '../../values/ExperienceSharing'

const ExperienceSharingList = () => {

   const [ExperienceSharingArray, setExperienceSharingArray] = useState(ExperienceSharing)

   // useEffect(() => {
   //    getExperienceSharingArray((experienceSharingArray) => {
   //       setExperienceSharingArray(experienceSharingArray)
   //    })
   // }, [])

   return (
      <div className="experience-sharing-wrapper">
         {
            ExperienceSharingArray.map((ExperienceSharing, i) => {
               return <ExperienceSharingItem ExperienceSharingObject={ExperienceSharing} i={i} />
            })
         }
      </div>
   )
}

export default ExperienceSharingList