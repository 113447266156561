import React, { useContext } from 'react';
import userContext from '../../context/user-context'
// import Loader from 'react-loader-spinner'
// import Spinner from 'react-bootstrap/Spinner'
// import ClipLoader from "react-spinners/CircleLoader";
import Spinner from '../BaseComponent/Spinner'
import strings from '../../strings/navigator'
const NotAcceptedTermsOfUse=()=>{

  const {user} =useContext(userContext);      

    // let hasNoPermission=((user.hasAcceptedTermsOfUse===null||user.hasAcceptedTermsOfUse===false));
    // let hasPermission=user.hasAcceptedTermsOfUse
    let PermissionInCheck=user.hasAcceptedTermsOfUse===undefined&&localStorage.getItem('hasAcceptedTermsOfUse')==="true"
   const JsxForNoPermission=(
   <h1>
       {/* {strings.newUser.body} */}
Dear user<br/>
To use this site you must agree to the terms of use<br/>
On most pages of the site - can not be viewed without consent to the terms of use<br/>
To agree on the terms of use - click on the link in the message above<br/>
</h1>)

const JsxForPermissionInCheck=(
<Spinner/>
)
    return(
        PermissionInCheck?JsxForPermissionInCheck:JsxForNoPermission 
    )
}

export default NotAcceptedTermsOfUse