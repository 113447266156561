import React, {useState, useContext} from 'react'
import Popup from '../Containers/Popup'
import EmailInput from '../BaseComponent/EmailInput'
import {saveMessage} from '../../firebase/DbConnection'
import userContext from '../../context/user-context'
const SupportPopup=()=>{

    const {user}=useContext(userContext)

const [EmailValue, setEmailValue]= useState("")
const [Name, setName]=useState("")
const [Message,setMessage]=useState("")

const [Control, setControl]=useState(true)
const onDataChange=(e)=>{
    switch(e.target.className){
case("name"):
setName(e.target.value)
break
case("message"):
setMessage(e.target.value)
break

    }
}
const onSend=()=>{
if(Name.length<3||EmailValue.length<5||Message.length<5){alert("All data must be filled in completely")}
else{
    saveMessage("system",user.uid?user.uid:"no-user",Name,null,Message,EmailValue)
    setControl(false)
}
}
    const title="Always happy to be at your service"
const body=(<div>
<h2>You can contact us for any matter</h2>
full name:
<textarea
        rows="1" 
        style={{width:"100%"}}
            type="text"
            className="name"
            placeholder="enter full name"
            // autoFocus
            value={Name }
            onChange={onDataChange}
          />
email: <EmailInput setEmailValue={setEmailValue}/>
The message:<textarea
        rows="8" 
        style={{width:"100%"}}
            type="text"
            className="message"
            placeholder="enter the message"
            // autoFocus
            value={Message}
            onChange={onDataChange}
          /> 

</div>)

const buttons=[{ButtonText:"Send and stay in touch",ButtonFunction:onSend}]

return <Popup Title={title} Body={body} Buttons={buttons}  RemoteControl={[Control,setControl]} OpenPopupButtonText="support"/>
}
export default SupportPopup