import React from 'react'

export default{


    title:"",
    body:(<div>Dear user<br/>
    To use this site you must first agree to the terms of use.<br/>
    You will not be able to view most pages on our site before you do so.<br/>
    To agree on the terms of use - click on the link in the message above</div>)
    
    }

export const Approval= {
    body:(<div>Welcome, Thank you for signing up. We hope you will be satisfied.<br/>
         You must accept the Terms Of Use before using our platform. </div>),
       toRead :"To read the Terms Of Use  Click Here" ,
       Confirm :"To Confirm, Click The Button",
       termsOfUse:"Accept Terms Of Use"  

}