import React, {useState} from 'react'
import Form from 'react-bootstrap/Form'


const EmailInput=({EmailValue="",setEmailValue=()=>{},i=-1})=>{
 

// Using useState here - I did because sometimes it does not update the email (when opening a new component) but it is not clear to me why it is not enough to use EmailValue and setEmailValue
const [EmailLocal,setEmailLocal]=useState((i===-1)?EmailValue:EmailValue[i])
const onDataChange=(e)=>{
  console.log("EmailInput>onDataChange>EmailValue:  ",EmailValue)
  setEmailValue(e.target.value,i)
setEmailLocal(e.target.value)
}

    return(
      <textarea
        rows="1" 
        style={{width:"100%"}}
            type="text"
            className="email"
            placeholder="enter email"
            autoFocus
            value={EmailLocal }
            onChange={onDataChange}
          />
  
)
}
export default EmailInput