import React, { useState, useEffect, useContext } from 'react';
import ChatText from './ChatText'
import ChatAdd from './ChatAdd'
import { updateChat } from '../../firebase/DbConnection'
import { updateChatStatus } from '../../firebase/DbConnection'
import userContext from '../../context/user-context'
import Popup from '../Containers/Popup'
import {getUserImgById} from '../../firebase/DbConnection'

const ChatBox = ({ employerId, jobId, freelancerId }) => {
  const { user, JobOffersAsArray } = useContext(userContext);
  const[ otherUserImgUrl,setOtherUserImgUrl ]=useState()
  const OtherUserUid=user.uid===employerId?freelancerId:employerId
  getUserImgById(OtherUserUid).then((photoURL)=>{setOtherUserImgUrl(photoURL)})

  const chat = (getChat(JobOffersAsArray, employerId, jobId, freelancerId))
  const addChat = (inputText) => {

    updateChat(employerId, jobId, freelancerId, inputText, user.uid)
  }
  const ChatBoxJsx = (<div className="chat-popup-wrapper">
    <div className="chat-history">
      {chat.slice(0).map((oneChat) => {

        return (
          <span>
            <ChatText key={oneChat.cid} {...oneChat} uid={user.uid} otherUserImgUrl={otherUserImgUrl} />
          </span>
        )
      })}
    </div>
    <div className="chat-input-controls">
      <ChatAdd employerId={employerId} jobId={jobId} freelancerId={freelancerId} uid={user.uid} addChat={addChat} />
    </div>

  </div>)
  return (
    // <Popup Title="chat" Body={ChatBoxJsx} AllowClosure={true} OpenPopupButtonText="open chat" RemoteControl={[true,()=>{alert ("show!!"+employerId+"  "+jobId+"  "+freelancerId)}]} />

    <Popup Title="chat" Body={ChatBoxJsx} AllowClosure={true} OpenPopupButtonText="open chat"

      RemoteControl={[true, () => { updateChatStatus(employerId, jobId, freelancerId, user.uid === freelancerId ? employerId : freelancerId) }]} />
  )
}

const getChat = (JobOffersAsArray, employerId, jobId, freelancerId) => {
  const jobWithChat = (JobOffersAsArray.filter((job) => {
    if (job.id === jobId && job.uid === employerId && job.chat && job.chat[freelancerId]) {
      return true
    }


  })[0]
  )
  const chatObject = jobWithChat ? jobWithChat.chat[freelancerId] : []

  const chatArray = Object.values(chatObject)
  console.log("ChatBow>getChat>chat:  ", chatArray)
  return (chatArray ? chatArray : [])
}
export default ChatBox