import React, { useEffect,useContext,useReducer, useState } from 'react'
import cookie from 'react-cookies'
// import ReactDOM from 'react-dom';
import '../css/index.css';
import '../styles/styles.scss';
import {firebase} from '../firebase/firebase'
import Router from '../routers/AppRouter'
import UserContext from '../context/user-context'
import userReducer from '../Reducers/userReducer'
import {GetJobOffersAsArray} from '../firebase/DbConnection'
import {SaveUserInformation,GetOccupationalGender,addOccupationalGenderToUser,firstRegistration,addRatingsToUser,addAcceptTermsOfUseStatusToUser} from '../firebase/DbConnection'

let dispatchForOnAuthStateChanged;
let setJobOffersAsArrayForOnAuthStateChanged
const DataCasingApp=()=>{
  const [user, dispatch] = useReducer(userReducer, {})
  
  const[JobOffersAsArray,setJobOffersAsArray]=useState([])
 
  setJobOffersAsArrayForOnAuthStateChanged=setJobOffersAsArray
useEffect(()=>GetJobOffersAsArray(setJobOffersAsArray, user.uid),[])

  dispatchForOnAuthStateChanged=dispatch
  return(<UserContext.Provider value={{ user, dispatch, JobOffersAsArray }}>
    <Router  />
  </UserContext.Provider>
)}



firebase.auth().onAuthStateChanged((user) => {

console.log("onAuthStateChanged>user:  ",user)
    

  if (user) {
    firebase.auth().currentUser.getIdToken(true).then(token=>{
// console.log("onAuthStateChanged>token:  ",token)
//todo השורה הזאת - איני זוכר למה צריך אותה - אולי ניתנת למחיקה?- GetJobOffersAsArray
GetJobOffersAsArray(setJobOffersAsArrayForOnAuthStateChanged,user.uid)
cookie.save("authToken", token, { path: '/' })
    })
    dispatchForOnAuthStateChanged({type:'LOGIN',user})
    SaveUserInformation(user)
    // GetOccupationalGender(user,setOccupationalGenderForOnAuthStateChanged)
    addOccupationalGenderToUser(user,dispatchForOnAuthStateChanged)
    addRatingsToUser(user,dispatchForOnAuthStateChanged)
    {firstRegistration(user.uid)}
    addAcceptTermsOfUseStatusToUser(user,dispatchForOnAuthStateChanged)
  } else {
    cookie.remove("authToken")
      console.log("onAuthStateChanged  >>  no user")
      dispatchForOnAuthStateChanged({type:'LOGOUT'})
  }
});
export default DataCasingApp;