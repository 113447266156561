import React, { useState } from 'react'
import { updateChat } from '../../firebase/DbConnection'

const ChatAdd = ({ employerId, jobId, freelancerId, uid, addChat }) => {
    const [inputText, setInputText] = useState("")

    const addToChat = () => {
        if (inputText != "")
            // updateChat(employerId,jobId,freelancerId,inputText,uid)
            addChat(inputText)
        setInputText("")
    }

    const onInputTextChange = (e) => {
        console.log("value:  ", e.target.value)
        setInputText(e.target.value)
    }
    return (
        <div className="chat-message-input">
            <input
                className=" form-control chat-message-input"
                type="text"
                placeholder="Write something, I'm waiting to hear from you"
                value={inputText}
                onChange={onInputTextChange}
            />
            <div className="chat-message-button">
                {/* <button class="btn btn-outline-secondary" type="button" id="button-addon2"><h1>send</h1></button> */}
                <button type="button" class="chat-send-button" onClick={(v) => { addToChat() }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z" />
                    </svg>
                </button>
            </div>
        </div>
    )


}

export default ChatAdd;