import React, { useContext, useState, useEffect } from 'react'
import UserContext from '../../context/user-context'
import Popup from '../Containers/Popup'
import UserPicture from '../BaseComponent/UserPicture'
import { getProfile } from '../../firebase/DbConnection'
import EditSelfDescriptionPopup from './EditSelfDescriptionPopup'
import Explanation from '../BaseComponent/Explanation'
import DisplayRatingsForProfile from './DisplayRatingsForProfile'
import DisplayBankForProfile from './DisplayBankForProfile'
import { Button } from 'react-bootstrap'


const ProfilePopup = ({ ProfileOwnerId, gender = "asFreelancer" }) => {


    const { user } = useContext(UserContext)
    const [Profile, setProfile] = useState({})
    console.log(" ProfilePopup>user:  ", user)
    const setProfileItem = (newProfile) => {
        setProfile(newProfile)
    }

    console.log("ProfilePopup>Profile:  ", Profile)
    useEffect(
        () => { getProfile(ProfileOwnerId, gender, setProfileItem) }
        , [])



    const titleJsx = (
        <div className="profile-popup-head">
            <UserPicture photoURL={Profile.photoURL} className="header__title" />
            <h1>
                {Profile.displayName}
            </h1>
        </div>
    )


    const bodyJsx = (
        <div>
            <div className="profile-popup-body">
                <div className="line">
                    <div className="line-head">
                        Self description:
                </div>
                    <div className="line-content">
                        {Profile.description ? Profile.description : "Not yet entered"}
                    </div>
                </div>

                <div className="line">
                    <div className="line-head">
                        AuthenticationLevel:
                </div>
                    <div className="line-content">
                        <Explanation text="Authentication level means - how much the member has proven his identity, the tools with which he can be verified are: email, phone, credit number and more.   Ratings can range from 1 (low authentication) to 10 (high authentication)" />
                        {Profile.AuthenticationLevel ? Profile.AuthenticationLevel.level : 0}
                    </div>
                </div>

                {Profile.bank && <DisplayBankForProfile bank={Profile.bank} genderString={gender} />}

            </div>

            <div className="profile-popup-body">
                <DisplayRatingsForProfile uid={ProfileOwnerId} genderString={gender} />
            </div>
        </div>
    )

    const customComponent = [ProfileOwnerId === user.uid ? <div><EditSelfDescriptionPopup description={Profile.description} />
        <Button variant="primary" onClick={() => { getProfile(ProfileOwnerId, gender, setProfileItem) }}>Refresh profile</Button></div> : null]

    return <Popup Title={titleJsx} Body={bodyJsx} CustomComponent={customComponent} OpenPopupButtonText="see profile" />


}

export default ProfilePopup