import React, { useContext } from 'react';
import userContext from '../context/user-context'
import { BrowserRouter, Route, Switch, Link, NavLink } from 'react-router-dom';
import NotFoundPage from '../components/NotFoundPage';
import Header from '../components/header/Header';
import JobForm from '../components/newJob/JobForm'
import MyJobs from '../components/jobList/MyJobs'
import TermsOfUse from '../components/termsOfUse/TermsOfUse'
import ProjectTree from '../components/architect/ProjectTree'
import ArchitectBoard from '../components/architect/ArchitectBoard'
import HomePage from '../components/homePage/HomePage'
import SupportAccordion from '../components/support/InstructionPages/SupportAccordion'
import StopGetEmail from '../components/emails/StopGetEmail'
import Question from '../components/questions/Question'
import NotAcceptedTermsOfUse from '../components/termsOfUse/NotAcceptedTermsOfUse'

const AppRouter = () => {
  const { user } = useContext(userContext);
  console.log("AppRouter>user:  ", user)

  return (
    <BrowserRouter>
      <div>
        <Switch>
          {/* <Route path="/chat"   /> */}
          <Route component={Header} />
        </Switch>


        <Switch>
          <Route path="/" component={HomePage} exact={true} />
          {user.hasAcceptedTermsOfUse && <Route path="/AddNewJob" component={JobForm} />}
          {user.hasAcceptedTermsOfUse && <Route path="/MyJobs" component={MyJobs} />}
          {(!user.uid || user.hasAcceptedTermsOfUse) && <Route path="/jobOffers" component={MyJobs} />}
          {user.hasAcceptedTermsOfUse && <Route path="/ProjectTree/:id" component={ProjectTree} />}
          {user.hasAcceptedTermsOfUse && <Route path="/StopGetEmail/:uid" component={StopGetEmail} />}
          {user.hasAcceptedTermsOfUse && <Route path="/ArchitectBoard" component={ArchitectBoard} />}
          <Route path="/question/:qid" component={Question} />

          <Route path="/supportAccordion" component={SupportAccordion} />

          <Route path="/termsOfUse" component={TermsOfUse} />
          {/* <Route path="/chat" component={ChatBox} /> */}
          {user.uid&&!user.hasAcceptedTermsOfUse && <Route component={NotAcceptedTermsOfUse} />}
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
export default AppRouter;
