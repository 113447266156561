import React, {useContext, useEffect, useState} from 'react';
import {Card, Accordion, Button} from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import MessageItemBody from './MessageItemBody'

const MessagesItem=({message})=>{
  
    return(
<div>
    
    <Accordion>
    <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        
     <h3 style={ message.status==="unread" ? { fontWeight: 'bold' }:{ fontWeight: 'normal' } }> {message.text} </h3>
      <br/>
      <h6>Click to see more details</h6>
      </Accordion.Toggle >
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body><MessageItemBody  message={message}/></Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
</div>
    )
}

export default MessagesItem