

// const Convert=(firebaseObj,key,pKey,path)=>{
const Convert=(firebaseObj, path,spath,LevelAboveDescription)=>{
    if ((firebaseObj===undefined)||(firebaseObj===null)){ return null}
    else{
    
    let outcomeObj={children: []}
    Object.keys(firebaseObj).map((Objkey) => {
if(!Array.isArray(firebaseObj[Objkey])&&typeof firebaseObj[Objkey] === 'object' && firebaseObj[Objkey] !== null)
{outcomeObj.children.push(Convert(firebaseObj[Objkey],[...path, Objkey],spath+"/"+Objkey,firebaseObj.Description))}
else{
    outcomeObj[Objkey]=firebaseObj[Objkey]
}})
outcomeObj.id=path[path.length-1]
// outcomeObj.parent=pKey
outcomeObj.name=firebaseObj.title
outcomeObj.path=path
outcomeObj.spath=spath
if(LevelAboveDescription)outcomeObj.LevelAboveDescription=LevelAboveDescription

console.log("outcomeObj:  ",outcomeObj)
if (path.length===1) return ([outcomeObj])

return (outcomeObj)
    
    }
}

export default Convert