import React, { useContext } from 'react'
import userContext from '../../context/user-context'
import NoUserCarousel from './NoUserCarousel'
import FreelancerCarousel from './FreelancerCarousel'
import EmployerCarousel from './EmployerCarousel'


const HomePageCarousel = () => {
    console.log("HomePageCarousel")

    const { user } = useContext(userContext)
    const isEmployer = user.OccupationalGender === "employer"
    if (!user.uid) {
        return <NoUserCarousel />
    } else {
        if (isEmployer) {
            return <EmployerCarousel />
        } else return <FreelancerCarousel />
    }
}

export default HomePageCarousel