export default{
title:"Post a new Job",
enterTitle:"Please enter a job title (short name that reflects <br/> the purpose of the job)",
jobTitle: "Job Title",
shortJobDescription:"Please enter a short job description summarising the main purpose of the job",
longJobDescription:"Write a long detailed description for the work. Make sure to Include all the details required for the job here (All work requirement must be included here. Requirements not included in this  posting will not be binding)",
enterPaymentAmount:"Please Enter The Payment Amount",
enterFreelancerLevel:"Freelancer Level (Minimum Freelancer Grade That Can Get The Job)",
jobDeadline:"Job Deadline",
sendToGroup:" Send only to my group",
groupAlert:"If you choose to make  the job open exclusively to a group, only those who are registered in the group will be able to take the work",
selectedGroup:"the selected group is"

}
