import shuffleArray from '../actions/shuffleArray'

const ExperienceSharing=[
     {
      "displayName" : "mark ziv",
      "photoURL" : "https://cdn.stocksnap.io/img-thumbs/960w/people-men_XTFQU42CHE.jpg",
      "text" : "A brilliant idea, saving a lot of time and money, a real dream come true"
    },
     {
      "displayName" : "omer shiran",
      "photoURL" : "https://cdn.stocksnap.io/img-thumbs/960w/active-activity_IC2UDAUNFF.jpg",
      "text" : "I did not imagine how it could make things easier!"
    },
    {
      "displayName" : "עמר שירן",
      "photoURL" : "https://cdn.stocksnap.io/img-thumbs/960w/beautiful-fashion_LXBEITMWN1.jpg",
      "text" : "I sent a job to do and it just happened, unbelievable how easily jobs could be completed"
    },
     {
      "displayName" : "mark ziv",
      "photoURL" : "https://lh5.googleusercontent.com/-mY6ncdKh9uA/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rfDoI5ZOxp8JlSQzlEHGVpiALgcEw/photo.jpg",
      "text" : "It took me a while to figure out the method - but after realizing it - it really did help, I didn't realize how easy it could get!"
    },
     {
      "displayName" : "yanky shin",
      "photoURL" : "https://cdn.stocksnap.io/img-thumbs/280h/analog-camera_YCK0SQKB21.jpg",
      "text" : "For me as a freelancer, it is much more pleasant to work this way I choose the jobs I want and I know my good work is the key to the next job"
    },
    {
      "displayName" : "maitav shuvu",
      "photoURL" : "https://lh4.googleusercontent.com/-CEv_S24ouOU/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rdBD9LOk_2pOKfZuRtGXl0j33yq2Q/photo.jpg",
      "text" : "It's just fun to do like that, I know what I want and without debates and without exhausting negotiations as if they were happening on their own"
    }
]
  export default shuffleArray(ExperienceSharing)