import React, { useContext, useState } from 'react'
import Popup from '../Containers/Popup'
import { addParent } from '../../firebase/DbConnection'
import userContext from '../../context/user-context'
// import b from '../BaseComponent/LevelBar'
import strings from '../../strings/navigator'
const AddProject = () => {
    const { user } = useContext(userContext)
    const [projectName, setProjectName] = useState("")
    const [PopupState, setPopupState] = useState(true)
    const onProjectNameChange = (e) => {
        setProjectName(e.target.value)
    }
    const saveNewProject = () => {
        if (projectName.length < 5) { alert("The project name should be at least 5 characters") }
        else {
            addParent(user.uid, projectName)
            setProjectName("")
            // alert("Project saved")
            setPopupState(!PopupState)

            // window.location.reload(false);

        }
    }
    const BodyJsx = <center>
    <br/>
        <h2>
            Name the new project
    </h2>
    <br/>
        <h4>
            You can define the rest of the details from the project tree
    </h4>
    <br/>
        <input
            type="text"
            placeholder="The name of the project"
            className="project_name"
            value={projectName}
            onChange={onProjectNameChange}
        />

    </center>



    return <Popup Title={strings.woodArchitecture.title} Body={BodyJsx} RemoteControl={[PopupState, setPopupState]} Buttons={[{ ButtonText: "save project", ButtonFunction: saveNewProject }]} OpenPopupButtonText={<h2>Set up a new project</h2>} />


}

export default AddProject