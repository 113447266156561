const values={
    Permissions:{
        WITH_OUT:0,
        READ_ONLY:1,
        EDIT:2,
        CREATE_SUBCOMPONENT:3,
        EDIT_AND_CREATE_SUBCOMPONENT:4,
        DELETE:5,
        MANAGE:6
    }


}

export default values