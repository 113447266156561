import React from 'react'
import Popup from '../../Containers/Popup'

const RatingInstructionPopup=()=>{

    const BodyText=(<div>
     What is level ratings and what does it mean? <br/>
    Freelancers are ranked in the system by levels<br/>
    The lower the rating, the higher the risk, but the lower the expected salary<br/>
    The higher the rating, the lower the risk, but the higher the expected salary<br/>
    <br/>
    An employer who is not interested in high risks - we recommend that you require a level above 5
    <br/><br/>
    Freelancer starts at 3<br/>
    Less than 3 is problematic<br/>
    Over five risks are already really low
    <br/><br/>
    The more a freelancer has - the more good jobs and the better the reviews - the higher his ranking<br/>
    <br/>
    Each job posting - the employer is asked to limit the minimum rating required for his or her job<br/>
    Only freelancers above this rating can take the job
    <br/><br/>
    The employer also has a rating that could be a consideration in the freelancer's decision - whether to take the job<br/>
    See the rating in the enclosed profile for each job offer`
    </div>)

    return(<Popup Title="Explanation Rating level" Body={BodyText}  OpenPopupButtonText="Explanation Rating level"/>)
}

export default RatingInstructionPopup