import React from 'react'
import contentsArrays from '../Content/contentsArrays'
import { Accordion, Card, Button } from 'react-bootstrap';

const SupportAccordion = () => {

  return (
    <div className="support-accordion-wrapper">
      <center>  <h1 className="title">general questions</h1></center>
      <Accordion className="support-accordion">
        {contentsArrays.bothQuestions.map((record, i) => {
          return (<Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={i + 1}>
                {record.Header}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={i + 1}>
              <Card.Body>{record.Body}</Card.Body>
            </Accordion.Collapse>
          </Card>)
        })}
      </Accordion>

      <center><h1 className="title"> employer's questions</h1></center>
      <Accordion className="support-accordion">
        {contentsArrays.employerQuestions.map((record, i) => {
          return (<Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={i + 1}>
                {record.Header}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={i + 1}>
              <Card.Body>{record.Body}</Card.Body>
            </Accordion.Collapse>
          </Card>)
        })}
      </Accordion>

      <center> <h1 className="title">freelancer's questions</h1></center>
      <Accordion className="support-accordion">
        {contentsArrays.freelancerQuestions.map((record, i) => {
          return (<Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={i + 1}>
                {record.Header}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={i + 1}>
              <Card.Body>{record.Body}</Card.Body>
            </Accordion.Collapse>
          </Card>)
        })}
      </Accordion>

    </div>
  )

}

export default SupportAccordion