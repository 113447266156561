import React, {useContext} from 'react';

const TermsOfUse=()=>{

    return(
    <div>
Procedures <br/>
All users of the site services agree with the procedures<br/>
License to use the site<br/>
The company reserves the right to terminate the services to any of the users in particular or at all - at its discretion and without compensation.<br/>
The Company reserves the right to change the procedures from time to time and consent to the procedures is also consent to all changes that apply.<br/>
The Company will not hide data that is supposed to be visible and will not promote and prefer a particular user over other users unless under uniform conditions for all and as specified in the procedures or support or in any other way that the Company deems appropriate and at its discretion.<br/>
The company reserves the right to close an account on the spot, which at its discretion, the user's conduct was not normal and / or not in accordance with the rules and / or there is a fear of hacking into the account and / or another person's use of it and / or any reason By the company - and it will not be liable for any damage or loss caused to the user or any other person in respect of this action<br/>
Signing this agreement includes agreeing to receive emails<br/>
<br/>Liability for damages<br/>
The company is in no way responsible for any damage caused by the use of the site or any service provided by it
The company is not responsible in any way for any damage caused to any of the parties by another party using the site
Right to the properties on the site<br/>
All assets on the site, including all data stored in its databases, will be the sole property of the company and it will be entitled to do so at its discretion and will not be required to transfer them to any other party, otherwise required by law.<br/>
Duty of users of the site<br/>
The user of the site undertakes not to falsify data and / or transfer incorrect data in any way that is against the other users and towards the company and not to enter or cause incorrect data to be entered from its databases - in each case the user will be responsible for any damage to the company or another user. At the Company's discretion and without the need to prove the damage on its part
In any case of concern about the reliability of the data of the transfer of funds or the performance of actions by the user of the site - the burden of proof will be on the user<br/>
<br/>Employer-freelancer relationship<br/>
An employer will not require or request from any other party to perform anything that is unfair or not in accordance with the law, including jobs that are  intended to steal data / infringe copyright, etc.<br/>
The freelancer undertakes to perform the work fairly and will not make any use of unauthorized materials whether or not they are copyrighted.<br/>
The freelancer undertakes to perform all the work he chooses to take on his own and not to use tools that are not intended for this.<br/>
In the event that the freelancer uses for the purpose of performing his work materials that are not intended for his use, and the employer is harmed as a result - the freelancer will be responsible for the damages caused to the employer.<br/>
In the above case, too, the company will be the mediating and decisive winemaker between the parties<br/>
In any case of disagreement between parties using the site (employer and freelancer) the company will be - the mediating and decisive factor between the parties and its decisions will be decisive and final<br/>
<br/>Means of attraction and deposit<br/>
The Company reserves the right to change the mode of deposit or continuation of the funds held in its possession<br/>
The Company reserves the right to honor a means of payment at its discretion<br/>
The company reserves the right to withhold users' funds in the event that there are doubts about their data / reliability or validity, at its discretion.<br/>
In the event that all means of withdrawal or deposit issued by the company will not be exercisable by the user - the company is not responsible for finding another way to perform these actions.<br/>
In the above case - and if the user offered (by applying for support) another way of performing the above actions and agreed by the company - all the expenses and commissions involved, for both parties, will be borne by him.<br/>
<br/>general<br/>
The Company may change the fees or terms of use at any stage<br/>
 In any complaint / lawsuit against the site - the authorized entity authorized to adjudicate is in the court of the Eretz Hemda Kollel only
    </div>
    )
}

export default TermsOfUse