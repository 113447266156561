import React from 'react'
import ClipLoader from "react-spinners/CircleLoader";

const Spinner=()=>{

    return(<div 
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      ><br/>
        <div>
        <ClipLoader
          
          size={150}
          color={"#123abc"}
          loading={true}
        />
       </div>
        I'm uploading data ... please wait
      </div>)
}

export default Spinner