import React, { useState, useContext, useEffect } from 'react';
import userContext from '../../context/user-context'
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize'
import { RegisterNewJob, VerifyFinancialAbility } from '../../firebase/DbConnection'
import SectionContainer from '../Containers/SectionContainer'
import GroupList from '../BaseComponent/GroupList'
import { getMyGroups } from '../../firebase/DbConnection'
import strings from '../../strings/navigator'
const JobForm = () => {

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [longDescription, setLongDescription] = useState('')
  const [level, setLevel] = useState(5)
  const [date, setDate] = useState(moment().add(100, 'days'))
  const [amount, setAmount] = useState()

  const [generalNotice, setGeneralNotice] = useState()
  const [titleError, setTitleError] = useState([])
  const [descriptionError, setDescriptionError] = useState([])
  const [longDescriptionError, setLongDescriptionError] = useState([])
  const [levelError, setLevelError] = useState([])
  const [dateError, setDateError] = useState([])
  const [amountError, setAmountError] = useState([])
  const [CloseToGroup, setCloseToGroup] = useState(false)
  const [GroupName, setGroupName] = useState("all groups")
  const [calendarFocused, setCalendarFocused] = useState(false)
  const { user } = useContext(userContext);
  const [Groups, setGroups] = useState([])
  const [ShowAddJobButton, setShowAddJobButton] = useState(true)


  useEffect(() => { getMyGroups(user.uid, setGroups) }, [])
  if (user.OccupationalGender === "freelancer") {
    return <h1>this page is for the employers only</h1>
  }



  const onDataChange = (e) => {
    setShowAddJobButton(true)
    setGeneralNotice()
    switch (e.target.className) {
      case "title":
        setTitle(e.target.value);
        let letTitleError = []
        // setTitleError([])
        // console.log(typeof title)
        // setTitleError(["sssssssssss"])
        // console.log(...titleError)
        // if((typeof( e.target.value) != `string` )){letTitleError([...letTitleError,"the title must be string only"])}
        // console.log(...titleError)
        if (e.target.value.length < 5) { letTitleError = [...letTitleError, "The title must contain at least 5 characters"] }
        setTitleError(letTitleError)
        return;

      case "description":
        setDescription(e.target.value);
        setDescriptionError([]);

        return;

      case "longDescription":
        setLongDescription(e.target.value);
        setLongDescriptionError([]);
        return;

      case "level":
        setLevel(e.target.value);
        setLevelError([])
        // let letLevelError=[]

        if ((e.target.value < 1) || (e.target.value > 10)) { setLevelError("A minimum level should be between 1 and 10") }

        return;

      case "date":
        setDate(e.target.value);

        return;

      case "amount":
        if (!(e.target.value < 5)) setAmount(e.target.value);
        setAmountError([])
        // if ((!amount )||(!amount.match(/^\d{1,}(\.\d{0,2})?$/))) {
        //   setAmountError(["Invalid amount"])}
        if (e.target.value < 5) { setAmountError(["Minimum 5$ for work"]) }
        console.log(amount)
        console.log(amountError)
        return;
      default: console.log("")
    }
    console.log(titleError)
  }

  const onFocusChange = ({ focused }) => {
    setCalendarFocused(focused)
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setShowAddJobButton(false)
    if (!title) setTitleError("Please fill in this component:")
    if (!description) setDescriptionError("Please fill in this component:")
    if (!longDescription) setLongDescriptionError("Please fill in this component:")
    if (!level) setLevelError("Please fill in this component:")
    if (!date) setDateError("Please fill in this component:")
    if (!amount) setAmountError("Please fill in this component correctly:")

    if ((!!title) && (!!description) && (!!longDescription) && (!!level) && (!!date) && (!!amount) &&
      (titleError.length +
        descriptionError.length +
        longDescriptionError.length +
        levelError.length +
        dateError.length +
        amountError.length) === 0) {


      VerifyFinancialAbility(user.uid, amount).then((verification) => {
        if (verification) {
          setGeneralNotice("Just a minute, the data is on the save...");
          const createAt = moment();
          RegisterNewJob(user.uid,
            title,
            description,
            longDescription,
            level,
            date,
            amount,
            // At this point CloseToGroup contains true or false
            //todo - But later it will contain either false (when there is no group) or the group name
            CloseToGroup ? GroupName : false,
            createAt).then((key) => {
              cleanFields()
              setGeneralNotice("the data was saved!")
              setShowAddJobButton(true)
            })
            .catch(e => {
              setGeneralNotice('Problem registering data, job not saved: ' + e)
              setShowAddJobButton(true)
            })
        } else {
          setGeneralNotice("There is not enough money in the account, an additional amount must be deposited to allow this work")
          setShowAddJobButton(true)
        }
      }).catch(e => {
        setGeneralNotice('Problem verification details, job not saved: ' + e)
        setShowAddJobButton(true)
      })

    }


  }
  const cleanFields = (e) => {
    setCloseToGroup(false)
    setTitle('')
    setDescription('')
    setLongDescription('')
    setLevel(5)
    setDate(moment().add(100, 'days'))
    setAmount(5)
    setGeneralNotice()

  }

  return (<center>
    {/* <SectionContainer header="Broadcast a new job"> */}
    <div className="job-form">

      <div className="page-title">
       
        {strings.newJob.title}
      </div>

      <div className="form-wrapper">

        <form className="form-container">
          {/* <form> */}
          {generalNotice && <h2 className="form__error">{generalNotice}</h2>}
          <br />
          <div>
            {titleError && <h2 className="form__error">{titleError}</h2>}
         {strings.newJob.enterTitle}
        <br />
            <textarea
              rows="1" cols="90"
              type="text"
              className="title"
              placeholder={strings.newJob.jobTitle}
              autoFocus
              value={title}
              onChange={onDataChange}
            />

          </div>
          {/* <br/> */}
          <div>

            {descriptionError && <h2 className="form__error">{descriptionError}</h2>}
          {strings.newJob.shortJobDescription}
          <br />
            <textarea
              rows="4" cols="90"
              type="text"
              className="description"
              placeholder="Description"
              value={description}
              onChange={onDataChange}
            />

          </div>
          {/* <br/> */}
          <div>

            {longDescriptionError && <h2 className="form__error">{longDescriptionError}</h2>}
            {strings.newJob.longJobDescription}
            <textarea
              rows="8" cols="90"
              placeholder="Add a long description for the work  Include all the details required for the job here"
              className="longDescription"
              value={longDescription}
              onChange={onDataChange}
            >
            </textarea>
          </div>
          <br />
          <div className="amount-container">
            <div className="container-left">
              {amountError && <h2 className="form__error">{amountError}</h2>}
              <div className="content-head">
                {strings.newJob.enterPaymentAmount}
              </div><br/>
              <div className="content-desc">
                In US dollars ($)
              </div>
              <input
                type="number"
                placeholder="Add amount"
                className="amount"
                value={amount}
                onChange={onDataChange}
              />
            </div>
            <div className="container-right">
              {levelError && <h2 className="form__error">{levelError}</h2>}
              <div className="content-head">
                {strings.newJob.enterFreelancerLevel}
                </div>
              <div className="content-desc">
                Between 1 and 10
                </div>
              <input
                type="number"
                placeholder="Add level"
                className="level"
                value={level}
                onChange={onDataChange}
              />
            </div>
          </div>

          <div className="date-container">

            <div className="content-head">
              {strings.newJob.jobDeadline}
              </div>
            <SingleDatePicker
              date={date}
              onDateChange={setDate}
              focused={calendarFocused}
              onFocusChange={onFocusChange}
              numberOfMonths={1}
            // isOutsideRange={() => false}
            />

          </div>
          {/* <br/> */}

          {/* <br/> */}

          <br />
          {Groups.length ? <div><input type="checkbox" checked={CloseToGroup} onChange={() => {
            setCloseToGroup(!CloseToGroup)
            if (!CloseToGroup) { alert(
              strings.newJob.groupAlert
              // " If you choose to close the work to a group, only those who are registered in the group will be able to take the work"
              ) }
          }} />
          {strings.newJob.sendToGroup}
          </div> : null}
          <br />
          {CloseToGroup && <GroupList setState={setGroupName} />}
          <br />
          {ShowAddJobButton && <button onClick={onSubmit} className="action action-one">Add job</button>}
          <button 
          onClick={(e)=>{e.preventDefault();
            cleanFields()}} 
            className="action">
              clean Fields
              </button>
        </form>

      </div>

    </div>
    {/* </SectionContainer> */}
  </center>
  )
}
// }
export { JobForm as default }