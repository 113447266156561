
import strings from '../../../strings/navigator'

const employerQuestions=strings.EmployerQuestions

const freelancerQuestions=strings.FreelancerQuestions

const bothQuestions=strings.GeneralQuestions


export default {bothQuestions, freelancerQuestions, employerQuestions}