import React, { useState, useEffect, useContext } from 'react';
import Popup from '../Containers/Popup'
import axios from 'axios'
import AccountItem from './AccountItem'
import userContext from '../../context/user-context'
const DbConnection = require('../../firebase/DbConnection')

const AccountDashboard = () => {
    const { user } = useContext(userContext);

    const [BankDetails, setBankDetails] = useState({})
    useEffect(() => {
        const uid = user.uid
        DbConnection.getAccountDashboard(uid).then(res => {
            setBankDetails(res.data)
        })
    }, [])
    console.log("AccountDashboard>BankDetails: ", BankDetails)

    const PopupTitle = (
        <h1 className="account-dashboard-popup-title">
            Account Dashboard
        </h1>
    );

    const bodyJsx = (
        <div className="account-dashboard-popup-body">

            <h1>Available  : {BankDetails.Available && <span className="item-value"> {BankDetails.Available.total + "$"}</span>}</h1>
            <br />
            <AccountItem name="Bonuses" obj={BankDetails.Bonuses} />
            <br />
            <AccountItem name="Deposits" obj={BankDetails.Deposits} />
            <br />
            <AccountItem name="OutboundBonus" obj={BankDetails.OutboundBonus} />
            <br />
            <AccountItem name="Payments" obj={BankDetails.Payments} />
            <br />
            <AccountItem name="Profits" obj={BankDetails.Profits} />
            <br />
            {/* <AccountItem name="ductility" obj={BankDetails.ductility} /> */}
            {/* <br /> */}

            <h1 className="item-status-head"> <span className="item-status">status:</span> {BankDetails.status && BankDetails.status}</h1>


        </div>
    )

    return (<Popup Title={PopupTitle} Body={bodyJsx} OpenPopupButtonText="Account Dashboard" />)
}


export default AccountDashboard