import React, {useState} from 'react'
import FreelancerTerminationChainDefinition from './FreelancerTerminationChainDefinition'
import EmployerTerminationChainDefinition from './EmployerTerminationChainDefinition'

import Popup from '../Containers/Popup'

const WorkFinishingProcedure=({eId,jId,fId,Reported})=>{
    const [index,setIndex]=useState(0)
    const [Thread, setThread]=useState(0)
    const addToIndex=(num)=>{setIndex(index+num)}
    const changeThread=(numThread,numIndex=0)=>{
        setThread(numThread)
        setIndex(numIndex)
    
    }
    console.log("WorkFinishingProcedure, eid  ",eId)
    console.log("WorkFinishingProcedure, jid  ",jId)
    console.log("WorkFinishingProcedure, fid  ",fId)
    let PopupValues
    if(Reported==="freelancer")
   { PopupValues =FreelancerTerminationChainDefinition(addToIndex,eId,jId,fId)}
    else{
    PopupValues =EmployerTerminationChainDefinition(addToIndex,eId,jId,fId,changeThread)[Thread]
}
console.log("FreelancerTerminationChainDefinition[0]",FreelancerTerminationChainDefinition())
    return <Popup {...PopupValues[index]}/>
}

export default WorkFinishingProcedure