import React, {useContext} from 'react';
import ApprovalButton from './ApprovalButton'
import { NavLink } from 'react-router-dom';
import userContext from '../../context/user-context'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert} from 'react-bootstrap';
import strings from '../../strings/navigator'

const RequireApprovalMessage=()=>{
    const {user}= useContext(userContext)
    let hasNoPermission=((user.hasAcceptedTermsOfUse===null||user.hasAcceptedTermsOfUse===false));
    let hasPermission=user.hasAcceptedTermsOfUse
    let inCheck=user.hasAcceptedTermsOfUse===undefined&&localStorage.getItem('hasAcceptedTermsOfUse')==="true"
    let noUser=!user.uid
    let hideMessage=(noUser||(!hasNoPermission && (hasPermission || inCheck)))



// if (user.uid&&!user.hasAcceptedTermsOfUse){return (
if (!hideMessage){return (

<div>
<Alert  variant="warning">
    <center>
    <h3 style={{color:"red"}}>
        {strings.Approval.body}
{/* Welcome, we are glad you joined our service <br/>
Hope you will be satisfied <br/>
In order to use our services you must accept the terms of use  <br/> */}
<NavLink to="/TermsOfUse" > 
{strings.Approval.toRead}
 {/* To read the Terms of Use click here   */}
 </NavLink>  <br/>
 {strings.Approval.Confirm}
{/* To confirm, click the button */}
  <br/>
<ApprovalButton/>
</h3>
</center>
</Alert>
<br/>
</div>
)}
return null
}

export default RequireApprovalMessage