import React from 'react'
import HomePageCarousel from './HomePageCarousel'
import ExperienceSharingList from './ExperienceSharingList'


const HomePage = () => {
    console.log("HomePage")

    return (
        <div>
            <HomePageCarousel />
            <ExperienceSharingList />
        </div>
    )
}

export default HomePage