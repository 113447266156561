import React, {useState, useContext} from 'react'
import userContext from '../../../context/user-context'
import Form from 'react-bootstrap/Form'
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Popup from '../../Containers/Popup'
import {SignExecution, VerifyFinancialAbility} from '../../../firebase/DbConnection'
import {UpdateComponent} from './DefineSubcomponent'
import strings from '../../../strings/navigator'

const SubmitForExecution=({item})=>{
    // const Buttons=[{"What does it mean to \"send to execution\""}]
const {user}=useContext(userContext)
    const [Amount,setAmount]=useState(5)
    const [Level,setLevel]=useState(3)
const onDataChange=(e)=>{
switch (e.target.className){
    case("amount"):
    if(e.target.value>=5){setAmount(e.target.value)}
    console.log ("SubmitForExecution> onDataChange> amount:  ", e.target.value)
    break
    case("level"):
    if(e.target.value>=1 && e.target.value<=10){setLevel(e.target.value)}
    console.log ("SubmitForExecution> onDataChange> level:  ", e.target.value)
    break
}
}

    const explanation=<Popup 
                    Title={"What does it mean to \"send to execution\""} 
                    Body={<h2>Sending to execution means <br/>
                        That the work can already be submitted for execution<br/>
                        That means the work is already set up and ready for publication<br/>
                        So any freelancer who is interested can take it<br/>
                        If you choose to send for execution you need to complete additional details such as:<br/>
                        How much you want to pay for the job?<br/>
                        And what are your requirements from the freelancer<br/>
                      
                        After sending for execution<br/>
                        The work will be published in the Projects section<br/>
                        Note: Once submitted for execution, the component and its roots cannot be edited and it cannot be subdivided<br/>
                        (You can still continue with the other components of the project and give them sub-components)</h2>}
                    OpenPopupButtonText={"What does it mean to \"send to execution\""} />
    
    const Body=(<div>
    {/* Before submitting for execution, make sure the details are complete and well understood and explained <br/>
    Work that is not clearly defined - will not produce the desired results<br/>
    You can click Edit to complete and edit the details <br/>
    If the work is already ready for execution -<br/>
    Complete the following:<br/> */}
    {strings.submit.text}
    <h3>
    {/* What is the price you would like to pay for this task?
    (Minimum $ 5)<br/> */}
    {strings.submit.priceTitle}<br/> 
    <input
            type="number"
            placeholder="Add amount"
            className="amount"
            value={Amount}
            onChange={onDataChange}
          />$
    <h6>{strings.submit.note}
        {/* Please note: There will be no price negotiations, with it a fair price - will not attract freelancers */}
        </h6><br/>
  {strings.submit.freelancerLevel}
    {/* freelancer Level <br/>(Minimum freelancer grade that can get the job)<br/>(Between 1 and 10) */}
          {/* <br/> */}
          
<input
            type="number"
            placeholder="Add level"
            className="level"
            value={Level}
            onChange={onDataChange}
          />
          <h6>
              {strings.submit.Note}
              {/* Note - the higher the rating, the higher the freelancer level<br/>
And you can rest assured that the work will be done best<br/>
On the other hand, the compensated salary is higher */}
</h6>

    </h3>
</div>)

const ExecutionApproveButton={
    ButtonText:"Confirm, send to execution",
    ButtonFunction:()=>{
        if(item.Description.length<20||item.Details.length<100){alert(`The description and details must be completed!
        Minimum description length - 20 characters.
        Minimum length for details 100 characters.
        Highly recommended - more!${<br/>}
        Click Edit.
Complete the details and then submit !`)}
        else{
            VerifyFinancialAbility(user.uid, Amount)
            .then((verification)=>{
                if(verification){
        SignExecution(user.uid,item.spath,Amount,Level,item)
        alert("Confirm, send to execution")}
        else{alert("There is not enough money in the account, an additional amount must be deposited to allow this work")}})
        .catch(e=>alert('Problem verification details, job not saved: '+e))
    }}}


    return <Popup Title={"Submit "+item.title+" for execution" } Body={Body} CloseButtons={[ExecutionApproveButton]} CustomComponent={[explanation, <UpdateComponent item={item}/>]}  OpenPopupButtonText="Submit for execution"/>

}

export default SubmitForExecution