import {GeneralQuestions,EmployerQuestions,FreelancerQuestions} from './support/q&a'
import header from './header'
import newJob from './newJob'
import viewMyJobs from './viewMyJobs'
import woodArchitecture, {addSonInTree,submit,finishedWork,deleteWork,descriptionNotEntered} from './woodArchitecture'
import newUser,{Approval} from './newUser'
// import addSonInTree from './addSonInTree'



export default{GeneralQuestions,EmployerQuestions,FreelancerQuestions,
header,
newJob,
viewMyJobs,
woodArchitecture,
addSonInTree,
submit,
finishedWork,
deleteWork,
descriptionNotEntered,
newUser,
Approval


}