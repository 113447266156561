
export default  {
   
      apiKey: "AIzaSyBA76_foXWyLHS_rqcWc4TOyAC84Yd0-3M",
  authDomain: "employers-for-nodejs.firebaseapp.com",
  databaseURL: "https://employers-for-nodejs.firebaseio.com",
  projectId: "employers-for-nodejs",
  storageBucket: "employers-for-nodejs.appspot.com",
  messagingSenderId: "828101708990",
  appId: "1:828101708990:web:03e1bc4b42cf22e79a62ef",
  measurementId: "G-Y1B0GDZ3BW"
    };
