import React, {useState, useContext} from 'react'
import userContext from '../../../context/user-context'
import Form from 'react-bootstrap/Form'
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Popup from '../../Containers/Popup'
import values from '../../../values'

const ShowDetels=({item, permission})=>{
//    const itemKeys  =Object.keys(item);
   
    const bodyJsx=(<div>
        <h2>
            {item.LevelAboveDescription&&"Level Above Description:  "+item.LevelAboveDescription}<br/>
            <h6>{item.LevelAboveDescription&&"This is not the task to do but the task of the level above, it is brought here for ease in understanding the task and giving a general view"}</h6>
        {item.Description&&"Description:  "+item.Description}<br/>
        {item.Details&&"Details:  "+item.Details}<br/><br/>
        {item.Requirements&&"Requirements:  "+item.Requirements}<br/>
        {item.Conditions&&"Conditions:  "+item.Conditions}<br/>
        {item.Links&&"Links:  "+item.Links}<br/>
        {item.ServiceProviders&&"Service Providers:  "+item.ServiceProviders} <br/>
        {item.ServiceRecipients&&"Service Recipients:  "+item.ServiceRecipients}<br/>
        {/* Permissions:{"  "+item.permissions}<br/> */}
        {permission===values.Permissions.MANAGE&&item.permissions&&Object.keys(item.permissions).map((key)=>{
            return(<div>
            email:{"  "+item.permissions[key].email+"   "}
            pemission:{"  "+item.permissions[key].permission}
            </div>)
        })}
        {/* Service Providers:{"  "+item.ServiceProviders}<br/> */}
        <br/>
        Last date for component:{"  "+item.Date}<br/>


        </h2>
{/* {itemKeys.map((key)=>{
if((typeof item[key] === 'object') || item[key] === null) return (<div></div>)

    return (<div>
        <h2>
        {key+":  "}
       { item[key]}
       </h2>
    </div>)
})} */}
        
    </div>)

return(
    <Popup Title={item.title} Body={bodyJsx} OpenPopupButtonText="Show detels"/>
)
// return (<Popup Title={"Set up a new sub-task for "+item.title} Body={bodyJsx}  Buttons={[{ButtonText:"save task",ButtonFunction:saveComponent}]}  RemoteControl={[PopupState,setPopupState]} OpenPopupButtonText="Define a new subcomponent"/>)

}
export default  ShowDetels