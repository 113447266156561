import React, { useContext, useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap'
import { getProjectsAsArray } from '../../firebase/DbConnection'
import userContext from '../../context/user-context'
import AddProject from './AddProject'

const ArchitectBoard = () => {
    const { user } = useContext(userContext);
    const [projects, setProjacts] = useState([])

    console.log("todo>  ArchitectBoard>  Set editing privileges (for non-owners)")

    useEffect(getProjectsAsArray(user.uid, setProjacts), [])
    //useEffect(()=>{getProjectsAsArray(user.uid,setProjacts)},[])


    // useEffect(getProjectsAsArray(user.uid,setProjacts),[])
    //  const externalSetProjacts=(ProjectsAsArray)=>{setProjacts(ProjectsAsArray)}
    //  useEffect(()=>{
    //     getProjectsAsArray(user.uid,(p)=>{externalSetProjacts(p)})
    //     // .then((projectsList)=>{

    //     //     setProjacts(projectsList)
    //     // })
    // },[])


    console.log("ArchitectBoard>>projects:  ", projects)

    if (projects.length === 0) {
        return <div className="architect-board-wrapper">
            <center>
                <AddProject />
                <br />
            </center>
            <h2>nothing wet</h2>
        </div>
    }
    else {
        return (<>
            <div className="architect-board-wrapper">
                <center>
                    <AddProject />
                    <br />
                </center>
                <ListGroup className="items-list">
                    {projects.map((project) => {
                        const ref = "/ProjectTree/" + project.id
                        return (<ListGroup.Item action href={ref} key={project.id}>
                            <h2>{project.name}</h2>
                        </ListGroup.Item>
                        )
                    })}

                </ListGroup>
            </div>
        </>
        )
    }
}

export default ArchitectBoard