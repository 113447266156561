import React, {useContext, useEffect, useState} from 'react';
import MessagesItem from './MessagesItem'

const MessagesList=({messagesArray=[]})=>{
    console.log("MessagesArray:  messagesList  ",messagesArray)

return(<div>
    {messagesArray.map((message)=>{return(message.status!="archive")?(<MessagesItem key={message.mid} message={message}/>):null})}
    </div>)
}
export default MessagesList