import React, { useState, useContext } from 'react';

const Question=({question})=>{
    if(!question.answer)return null

    return(
        <div>
question:  {question.question}
<br/>
answer:   {question.answer.answer}            
        </div>
    )
}
export default Question