import database from './firebase'


const GetJobOffersAsArray = async (uid, doAfter) => {
    
        //black list----blackList
        let blackList = {}
        let group = {}
        database.ref(`group/${uid}`).once("value")
            .then((dataSnapshot) => {
                if (dataSnapshot.val()) {
                       group = dataSnapshot.val()
                }


            })

        database.ref(`Blacklists/${uid}`).once("value")
            .then((Snapshop) => {
                if (Snapshop.val()) {
                    blackList = Snapshop.val()
                }
               
                database.ref("NewJob").on('value', (snapshot) => {
                    const jobArray = [];
                    if (snapshot.val()) {
                        Object.keys(snapshot.val()).forEach(function (eid) {



                            const userIdJobs = snapshot.val()[eid];
                            Object.keys(userIdJobs).forEach(function (key) {

                                if (!(blackList[eid])) {
                                    if (userIdJobs[key].CloseToGroup) {
                                        //שמירת שם הקבוצה של העבודה במשתנה
                                        const groupName = userIdJobs[key].CloseToGroup
                                       //בדיקה האם היוזר הוא המעסיק אם כן מציג לו בכל מקרה את העבודה
                                        if (eid != uid) {
                                            //all groups שליחה לכל הקבוצות של המעסיק אם בחר ב

                                            if (group[eid] && groupName==="all groups")
                                            {
                                              
                                                jobArray.push({ ...userIdJobs[key], id: key, uid: eid })
                                            }
                                            else{
                                                const AllGroup="all groups"
                                                //בדיקה האם קיים בקבוה או שקיים ב all goups
                                                if (group[eid] && group[eid][groupName]||group[eid] && group[eid][AllGroup]) {
                                                     jobArray.push({ ...userIdJobs[key], id: key, uid: eid })
                                                }

                                            }
                                        
                                        }
                                        else {
                                            jobArray.push({ ...userIdJobs[key], id: key, uid: eid })
                                        }
                                    } else {
                                        jobArray.push({ ...userIdJobs[key], id: key, uid: eid })

                                    }


                                }

                            })

                        })
                    }
                    // מיון המערך
                    jobArray.sort((a, b) => parseFloat(b.createAt) - parseFloat(a.createAt));
                    let filterjobArray = []
                    let k = 0;
                    for (var j = 0; j <= jobArray.length - 1; j++) {
                        // ChangeOfStatusAsTimeGoesOn(jobArray[j])
                        if (jobArray[j].uid === uid){
                            filterjobArray[k++] = jobArray[j]
                        }else{
                            if(jobArray[j].status){
                            if(jobArray[j].status.type === "available" && jobArray[j].date > Date.now()){
                                 filterjobArray[k++] = jobArray[j]
                             }
                             else{
                                 if(jobArray[j].status.id&&jobArray[j].status.id === uid)
                                 filterjobArray[k++] = jobArray[j]
                             }}
                        }
                           
                        }
                   
                  doAfter(filterjobArray)
                   
                })


            })



    // })

}
const addOccupationalGenderToUser=(user,doAfter)=>{
    database.ref(`users/RegistrationInformation/${user.uid}/OccupationalGender`).once('value')
    .then((snapshot) => {doAfter(snapshot.val())})

}

const getStatusAsFreelancer=(uid,doAfter)=>{

    database.ref(`users/RegistrationInformation/${uid}/statusAsFreelancer`).on('value', 
        doAfter

)}


const getMessages = async (uid,doAfter) => {
        database.ref(`messages/${uid}`).on('value', doAfter)
}


const getProjectsAsArray = async (uid, doAfter) => {
    
        database.ref(`architect/${uid}`).on('value', doAfter)
    
}


const getTree = async (uid, pid, doAfter) => {
    
        database.ref(`architect/${uid}/${pid}`).on('value',doAfter)
}

// ----------------------------------untill here on calling
// ----------------------------------from here once calling
const getMyGroups = (employerId) => {
    return new Promise((resolve, reject) => {
       
        let groupArr = []
        let myGroups = []
        database.ref(`group`).once('value')
            .then((snapshop) => {
                const freelancerIds = snapshop.val()
                for (let freelancerId in freelancerIds) {
                  
                    if (freelancerIds[freelancerId][employerId]) {

                        myGroups.push({ ...freelancerIds[freelancerId][employerId] })
                        groupArr = myGroups.reduce(function (r, o) {
                            Object.keys(o).forEach(function (k) {
                                r.push(k);
                            });
                            return r;
                        }, []);

                    }
                }
                    if (groupArr.length) {
                      groupArr = groupArr.sort()
                      groupArr=["all groups",...groupArr]
                        //מחיקת כפולים מהמערך
                        var GroupArr = groupArr.filter(function (elem, index, self) {
                            return index === self.indexOf(elem);
                        })
                        
                    }else{
                        var GroupArr = []
                    }
                       resolve(GroupArr)
            })
    })
}


const addAcceptTermsOfUseStatusToUser = async (uid) => {
    return new Promise((resolve, reject) => {
        database.ref(`users/RegistrationInformation/${uid}/AcceptedTermsOfUse/Confirmed`).once('value')
            .then((Snapshot) => {
                resolve(Snapshot.val())
            })
    .catch((e) => {
        reject(e)
    })
})
}

const getUserNameById = (id) => {
    return  database.ref(`users/google/${id}/displayName`).once('value')
            
}

const getUserImgById = (id) => {
    return  database.ref(`users/google/${id}/photoURL`).once('value')
            
}

// -----------------------profile
const googleGetProfile = (ProfileOwnerId) => {
    return new Promise((resolve, reject) => {
        if (ProfileOwnerId === undefined || ProfileOwnerId === null) {
            resolve(null)
        }

        let profile = {}
        database.ref(`users/google/${ProfileOwnerId}`).once('value')
            .then((googleSnapshot) => {

                if ((googleSnapshot.val() != undefined) && (googleSnapshot.val() != null)) {
                    profile = { ...profile, displayName: googleSnapshot.val().displayName, photoURL: googleSnapshot.val().photoURL }
                    resolve(profile)
                }
            }).catch((e) => reject(e))
    })



}
    const getRatings = (uid, genderString) => {
        return new Promise((resolve, reject) => {
            const Rating = {}
            let nonWork = 0
            const Recommendations = []
            console.log("DBC<getRatings<uid, genderString",uid, genderString)
            database.ref(`Ratings/${uid}/${genderString}`).once('value')
                .then((Snapshot) => {
                    if (Snapshot.val) {
                        Rating.rating = Snapshot.val().rating
                        Object.keys(Snapshot.val()).map((key) => {
                            if (typeof Snapshot.val()[key] === 'object') {
                                Recommendations.push(Snapshot.val()[key][Object.keys(Snapshot.val()[key])[0]])
                                if (Snapshot.val()[key][Object.keys(Snapshot.val()[key])[0]].ReportingNonWork) nonWork++
                            }
                        })
                        Rating.recommendations = Recommendations
                        Rating.nonWork = nonWork
                        console.log("DBC<getRatings<Rating",Rating)
                        resolve(Rating)
                    }
                }).catch((e) => reject(e))
        })
    }





const DBDirect={
    GetJobOffersAsArray,
    getStatusAsFreelancer,
    addOccupationalGenderToUser,
    getMessages,
    getProjectsAsArray,
    getTree,

    getMyGroups,
    addAcceptTermsOfUseStatusToUser,
    getUserNameById,
    getUserImgById,

    googleGetProfile,
    getRatings,
}

export default DBDirect