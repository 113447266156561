import Popup from '../Containers/Popup'
import React, {useState, useEffect, useContext} from 'react'
import userContext from '../../context/user-context'
import DefineSubcomponent, {UpdateComponent} from './ArchitectPopupComponents/DefineSubcomponent'
import ShowDetels from './ArchitectPopupComponents/ShowDetels'
import DeleteComponent from './ArchitectPopupComponents/DeleteComponent'
import SubmitForExecution from './ArchitectPopupComponents/SubmitForExecution'
import values from '../../values'
import FinishReporting from './ArchitectPopupComponents/FinishReporting'
import strings from '../../strings/navigator'


const ArchitectPopup=({item, permission})=>{
  const {user}= useContext(userContext)
        const showUpdateComponent=((item.status==="designing")&&(permission===values.Permissions.EDIT||permission>=values.Permissions.EDIT_AND_CREATE_SUBCOMPONENT))
   const showSubmitForExecution=(item.status==="designing")&&(item.children.length===0)&&(permission===values.Permissions.MANAGE)
        const showDefineSubcomponent=(permission>=values.Permissions.CREATE_SUBCOMPONENT)&&(item.status==="designing"||item.status==="A partial execution sending")

    const customComponent=[
        <ShowDetels item={item} permission={permission} />,
        <>{showDefineSubcomponent&&<DefineSubcomponent item={item}/>}</>,
        <>{showUpdateComponent&&<UpdateComponent item={item}/>}</>,
        <>{showSubmitForExecution&&<SubmitForExecution item={item} />} </>,
        <>{item.status!=="Completed"&&<FinishReporting item={item}/>} </>,
        <> {(permission>=values.Permissions.DELETE)&&<DeleteComponent item={item}/>}</>
        ]

        
        return <Popup Title={item.title} 
        Body={item.Description?item.Description:(<div>{strings.descriptionNotEntered.body}</div>)}  
        CustomComponent={customComponent} 
        OpenPopupButtonText={{text:item.title,  variant:item.status!="designing"?item.status!="Completed"?"success":"secondary":"primary"}}/>
}
export default ArchitectPopup

// כשהגדרתי את החלונות הקובצים בתהליך של "סיום עבודה" השתמשתי בשיטה שלחיצה על כפתור בחלון הקופץ משנה את הערכים שלו בהתאם לערכים שמוגדרים במערך
// אני חושב שאפשר לבדוק אופציה שהכפתור עצמו יהיה כפתור של חלון קופץ ואז בלחיצה עליו יפתח החלון ואז לא נגדיר מערך אלא כל כפתור הוא קריאה לחלון קופץ שיוגדר בפני עצמו 
// אלא שאז צריך להגדיר בקובץ יסוד של החלון הקופץ אפשרות חדשה של כפתורים (שהם קריאה לחלון קופץ) בשם 
// PopupButtons 