import React,{useState,  useContext, useEffect} from "react"
import userContext  from '../../context/user-context'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import {getMyGroups} from '../../firebase/DbConnection'
import strings from '../../strings/navigator'


const GroupList =({setState})=>{
    const {user}=useContext(userContext)
const [Groups,setGroups]=useState([])
const [selectedGroup,setSelectedGroup]=useState("all groups")
useEffect(()=>{getMyGroups(user.uid,setGroups)},[])
const onSelectItem=(eventKey, event)=>{


    
        setSelectedGroup(Groups[eventKey])
        setState(Groups[eventKey])
    
   
    // alert(eventKey)
    // console.log("GroupList>evenbt:  ",event)
}



    return(<div>
    <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
        choose group
        </Dropdown.Toggle>
      
        <Dropdown.Menu>
        {Groups.map((group,i)=>{
            return ( <Dropdown.Item eventKey={i} onSelect={onSelectItem} >{group}</Dropdown.Item>)
        })}

        
        </Dropdown.Menu>
      </Dropdown>
      {strings.newJob.selectedGroup}: {selectedGroup}
      {/* the selected group is: {selectedGroup} */}
      </div>
    )

}

export default GroupList