import React, {useContext,useState} from 'react'
import userContext from '../../context/user-context'
import {DBDeleteJob, applyToJob} from '../../firebase/DbConnection'
import { Link } from 'react-router-dom';
import ChatBox from '../chat/ChatBox';
// import {Buttons} from 'react-bootstrap'
import ButtonBootstrap from 'react-bootstrap/Button';
import WorkFinishingProcedure from '../finishJob/WorkFinishingProcedure'
import ProfilePopup from '../profile/ProfilePopup'
import InabilityFinish from '../finishJob/InabilityFinish'


const Buttons = (props) => {
   
    // const {user} = useContext(userContext);
    const {user,dispatch} =useContext(userContext);
// const [isChatopen,setIsChatopen]=useState(false)
    const editJob = e => {
        alert(`Call a function to edit job # ${e.target.value}`)
    } 

    const deleteJob = e => {
        DBDeleteJob(user.uid, e.target.value)
    }
    const jobCompleted = e => {
        alert(`Call a function to change job # ${e.target.value} status to "Completed"`)
     }

    // const employeeProfile = e => {
    //     alert(`Call a function to show profile of employee # ${e.target.value}`)
    // }

    const jobApply = e => {
        props.setShowSpinner(true)
        applyToJob(props.employerId,props.id,user.uid,dispatch).then(()=>{ props.setShowSpinner(false)})
    }
   

    
    if (user.OccupationalGender === 'employer') {
        if(props.status.type === "available")  {
            return (
                <div>
                    <button
                        className='comment-submit submit'
                        value={props.id}
                        onClick={e => editJob(e)}
                    >
                    Edit
                    </button>
                    <button
                        className='comment-submit submit'
                        value={props.id}
                        name={props.title}
                        onClick={e => deleteJob(e)}
                    >
                    Delete
                    </button>
                    </div>
            ) 
        } else if(props.status.type === "taken") {
            return(
                <div>
                {/* <button
                className='comment-submit submit'
                value={props.id} 
                onClick={e => jobCompleted(e)}
                >
                Job Completed
                </button>    */}
                {/* <button
                className='comment-submit submit'
                value={user.displayName} 
                onClick={e => employeeProfile(e)}
                >
                Show employee profile
                </button> */}
                {/* <br/> */}
                <br/>
                <ProfilePopup ProfileOwnerId={props.status.id} gender="asFreelancer" />
                &nbsp;
                <ChatBox employerId={props.employerId} jobId={props.id} freelancerId={props.status.id}/>
                </div>
            )      
        } else
        if(props.status.type === "Pending approval")  {
            return ( <div>
   {(user.uid===props.employerId)&&<WorkFinishingProcedure eId={props.employerId} jId={props.id} fId={props.status.id} Reported="employer"/> }
   &nbsp;
   <ChatBox employerId={props.employerId} jobId={props.id} freelancerId={props.status.id}/>
</div>
            )
        }else return null     
    } else if(user.OccupationalGender === "freelancer") 
    if(props.status.type === "available")  {
    return (
            <div>
                <button
                className='comment-submit submit'
                value={props.title}
                    onClick={
                        user ? e => jobApply(e) :
                        () => alert('You must log in before using this service')
                    }  
                >
                Apply for this job
                </button>
                <button
                    className='comment-submit submit'
                    value={props.id}
                    onClick={props.showAsk}
                >
                Ask a question
                </button>
                <br/><br/>
                    <ProfilePopup ProfileOwnerId={props.employerId} gender="asEmployer" />
                    
            </div>
        )}else{if(props.status.type === "taken") {
            return(
                <div>
                  {(user.uid===props.status.id)&& <WorkFinishingProcedure eId={props.employerId} jId={props.id} fId={props.status.id} Reported="freelancer"/>}
               
                &nbsp;
                {(user.uid===props.status.id)&& <InabilityFinish  eId={props.employerId} jId={props.id} fId={props.status.id} />}
               
                &nbsp;
                <ChatBox employerId={props.employerId} jobId={props.id} freelancerId={user.uid}/>
                 {/* } */}
                </div>
            )     }} 
    else return null   
    return null 
}

export default Buttons;