import React, {useContext, useEffect, useState} from 'react';
import {Card, Accordion, Button} from 'react-bootstrap'
import userContext from '../../context/user-context'
import {sendAnswer,setMessageState,setQuestionStatus} from '../../firebase/DbConnection'


const MessageItemBody=({message})=>{
console.log("message:  ",message)
    const {user, JobOffersAsArray} = useContext(userContext)
const [answer,setAnswer]=useState("")
    
const onDataChange=(e)=>{
    switch(e.target.className){
        case 'answer':
            setAnswer(e.target.value)
            return
            
}}
const changeMessageState=(e)=>{
    console.log("e.target.className:  ",e.target.className)

    switch(e.target.className){
        case "MarkAsReadButton btn btn-primary":
            setMessageState(user.uid,message.mid,"read") 
                return

        case "MarkArchiveButton btn btn-primary":
            setMessageState(user.uid,message.mid,"archive") 
            return
            
    }

}
const  postAnswer=()=>{
sendAnswer(user.uid,message.context,message.value,answer,message.mid)
alert("Thanks for your response")
}

const deleteQuestion=()=>{
    setQuestionStatus(user.uid,message.context,message.value,"deleted")
    // setMessageState(user.uid,message.mid,"archive") 
}
const DefineBodyJsx=()=>{
    switch(message.content){
case "question":
    // let i=0
    // while(JobOffersAsArray[i]){
    //     if
    // }
  let jobOffer= JobOffersAsArray.filter((jobOffer)=>{
        if((jobOffer.id===message.context)&&(jobOffer.uid===user.uid))return true
        return false
    })
    
    // jobOffer[0].questions.filter((question)=>{
        // if
    // })
    // jobOffer[0].questions[message.value].question
if(!jobOffer.length){return(<div></div>)}
return(
<div>
    {message.text}
    <br/>

    about:
     {/* getWork(message.context).title */}
    { jobOffer[0].title}
    <br/>
    content:
    {/* getWork(message.context).questions.qid(=message.value).question */}
    { jobOffer[0].questions[message.value].question}
    <br/>
    your unswer:
    <textarea
          rows="8" cols="80"
          className='answer'
            value={answer}
            onChange={onDataChange}
          ></textarea>
<br/>
<Button onClick={postAnswer}>send unswer</Button>&nbsp;
<Button className="deleteButton" onClick={deleteQuestion}>delete question</Button>&nbsp;
<Button className="MarkAsReadButton" onClick={changeMessageState}>Mark as read</Button>&nbsp;
<Button className="MarkArchiveButton" onClick={changeMessageState}>Archive</Button>
</div>
)

    // case "dispute":




    // break
 default:
     return(
 <div>{message.text}</div>
 )
    }


}
   return DefineBodyJsx()
}

export default MessageItemBody