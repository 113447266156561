import React from 'react'
 import { StopGetEmails } from '../../firebase/DbConnection'

const StopGetEmail=(props)=>{
    
    const uid=props.uid?props.uid:props.match.params.uid
    
    const stopGetEmail=()=>{
        
         StopGetEmails(uid)
        alert ("No more emails will be sent to you")
    }
    return(

        <div>
            <br/>
            <h1>are you sure you want to stop get emails from direct done?
            
            <button onClick={stopGetEmail} >yes</button></h1>
        </div>
    )
}

export default StopGetEmail