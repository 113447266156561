import React from 'react'


export default{
    title:"Set Up A New Project",
    firstTitleArchitectBoard:(<div>To zoom in or out, press  CTRL  +or CTRL -</div>),
    secondTitleArchitectBoard:"Key: Red - Start. Blue - Before execution. Green - In execution proceedings. Gray - Completed.",
    reader:{
        title:"reader",
        red:"Red - Start.",
        blue:"Blue - Before execution.",
        green :" Green - In execution proceedings.",
        gray :"Gray - Completed."
    },
    addSunButton:"Define a new subtask"
    }

    export const addSonInTree= {
        title:"A short name that sums up the project",
        description:"The general idea of this job",
        detail:"In depth description of what is required",
        executionRequirements:"Execution requirements such as software language, server type, etc.",
        conditions:"Conditions e.g. : only if a credit card / minimum score is entered, etc.",
        exists:"Service Providers e.g.  when a database already exists that serves the project",
        links:"Related links e.g. examples and illustrations",
        date:"Deadline"
    }

export const submit = {
    title:"Submit New Sub For Execution",
    text:(<div>Before submitting for execution, make sure the details are complete and are easy to understand<br/>
    Work that is not clearly defined - will not produce the desired results<br/>
    You can click Edit to complete and edit the details<br/>
    If the work is already ready for execution -<br/>
    Complete the following:<br/></div>),
   priceTitle:" What  price you would like to pay for this task? (Minimum $ 5)",
   note:"Please note: There will be no price negotiations, unfair price - will not attract freelancers",
   freelancerLevel:(<div>freelancer Level<br/>
   (Minimum freelancer grade that can get the job)<br/>
   (Between 1 and 10)
   </div>),
   Note:(<div>Note - the higher the rating, the higher the freelancer level<br/>
   meaning you can be assured of top quality work<br/>
   yet the payment goes up with the level of skill required.</div>)
}

export const finishedWork = {
title:"The Work Will Be Reported As Completed",
body:(<div>Completed work - Cannot be sent for execution.<br/>
But it will still be seen in the architecture tree<br/>
Note:If the work was sent for execution, the work should also be deleted from the list of works</div>)
}

export const deleteWork = {
    title:"The Work Will Be Reported As Completed",
    body:(<div>Are you sure you want to delete this project? Deleting it will delete this project and its dependencies (if any). </div>)
    }

export const descriptionNotEntered = {
    body:(<div>Task description not yet entered - To enter the task description, click "Edit details"</div>)
    }