import React, { useState } from 'react'
import Popup from '../Containers/Popup'
import StripeButton from './StripeButton'
import PaypalButton from './PaypalButton'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

const DepositPopup = () => {
  const [Amount, setAmount] = useState(100)
  const onDataChange = (e) => {
    setAmount(e.target.value)
  }

  const PopupTitle = (
    <h1 className="deposit-popup-title">
      Deposit
    </h1>
  );

  const BodyJsx = (
    <div className="deposit-popup-wrapper">
      <h1>The big step to achieving the goal</h1>
      <h6>Minimum deposit 100$ <br />
Reminder: From the amount deposited will take 3% commission</h6>
      <br />
      <InputGroup className="mb-3">
        <FormControl
          value={Amount}
          onChange={onDataChange} />
        <InputGroup.Append>
          <InputGroup.Text>$</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>

      <div className="payment-action">
        {/* <PaypalButton amount={Amount} /> */}
        <button className="deposit-button">
          Deposit or Credit Card
        </button>
      </div>

      <h3 className="thanks-note">Thank you for depositing and hope you are satisfied with the completion of the project</h3>
    </div>
  )

  return <Popup Title={PopupTitle} Body={BodyJsx} OpenPopupButtonText="Deposit" />
}

export default DepositPopup