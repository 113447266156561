import React from 'react'
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'

const Explanation = ({ text }) => {



  return (<>
    &nbsp;
    <OverlayTrigger
      key={'top'}
      placement={'top'}
      overlay={
        <Tooltip >
          <h5>{text}</h5>
        </Tooltip>
      }
    >
      <button className="btn-circle explanation-button">?</button>
    </OverlayTrigger>
      &nbsp;&nbsp;
  </>

  )
}

export default Explanation