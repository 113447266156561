import React, {useContext, useState} from 'react'
import UserContext from '../../context/user-context'
import Popup from '../Containers/Popup'
import {saveUserSelfDescription} from '../../firebase/DbConnection'


const EditSelfDescriptionPopup=({description})=>{

    const [Description,setDescription]=useState(description)
    const {user}=useContext(UserContext)
    
    const onDataChange=(e)=>{
    setDescription(e.target.value)
    }
   const  buttons=[{ButtonText:"save",ButtonFunction:()=>{
         saveUserSelfDescription(user.uid, Description)
     }}]
    const bodyJsx=(
    <div>
        <textarea
        value={Description}
        onChange={onDataChange}
        />
    </div>
    )
        return <Popup Title="edit profile description" Buttons={buttons} Body={bodyJsx} OpenPopupButtonText="edit profile description"/>

}
export default EditSelfDescriptionPopup