import React , {useState, useContext,useEffect} from 'react';
import userContext from '../../context/user-context'
import JobOfferListItem from './JobOfferListItem'
import AskUs from "./AskUs"
import qs from 'query-string'
// import ClipLoader from "react-spinners/CircleLoader";
import Spinner from '../BaseComponent/Spinner'


const MyJobs=(props)=>{
   const {user, JobOffersAsArray} = useContext(userContext)
   const [first2secends,setFirst2secends]=useState(true)
   useEffect(()=>{setTimeout(()=>{setFirst2secends(false)}, 2000)},[])
   
        console.log("MyJobs>user:  ",user)
        console.log("MyJobs> JobOffersAsArray",JobOffersAsArray)
        console.log("MyJobs>window.location.pathname", window.location.pathname)
  
    let relevantJobOffersAsArray=getRelevantJobOffersAsArray(user, JobOffersAsArray, props);

        if( window.location.pathname==="/MyJobs"&&(user.OccupationalGender === "freelancer")){
          return <h1>this page is for the employers only</h1>}
          if( window.location.pathname==="/MyJobs"&&(!user.uid)){
            return <h1>you have to login - in order to see your jobs</h1>}
        if( user.uid&&window.location.pathname==="/jobOffers"&&user.OccupationalGender === "employer"){
            return <h1>this page is for the freelancer only</h1>}
    return(
      <div className="jobs-container">
      <div className="jobs-list">
        { relevantJobOffersAsArray.length === 0 ? (
          first2secends?<Spinner/>:
            <h1>No relevant Job Offers for you
              <br/>
              {user.statusAsFreelancer === "NotAvailable" && user.OccupationalGender === "freelancer" &&
              <div>You cannot take more than one job
              <br/>
              After you finish the job you have taken - you can take more jobs!</div>}
            </h1>
          ) : (
            relevantJobOffersAsArray.map((JobOffer, i) => {
               
                return <JobOfferListItem id={JobOffer.id} key={JobOffer.id} i={i} {...JobOffer} />;
              })
            )
        }
          </div>
       
      </div>
    );
}

const getRelevantJobOffersAsArray=(user, JobOffersAsArray,props)=>{
  let relevantJobOffersAsArray=[];
 
    relevantJobOffersAsArray=JobOffersAsArray.filter((JobOffer)=>{
      console.log("JobOffersAsArray: ",JobOffersAsArray)
      console.log("joboffer:  ",JobOffer)
if(qs.parse(props.location.search).reduceBy==='archive'){
  //console.log("MyJobs<getRelevantJobOffersAsArray<JobOffer: ",JobOffer)
  //console.log("MyJobs<getRelevantJobOffersAsArray<JobOffer.status.type: ",JobOffer.status.type)

  //||JobOffer.status.type==='Pending approval'

if(!(JobOffer.status.type==='deleted'|| JobOffer.status.type==='completed'|| JobOffer.status.type==='timepass'||JobOffer.status.type==='Pending approval'||JobOffer.status.type==='nonWork')){
  console.log("MyJobs<getRelevantJobOffersAsArray<JobOffer: ",JobOffer)
  console.log("MyJobs<getRelevantJobOffersAsArray<JobOffer.status.type: ",JobOffer.status.type)
  return false
}
}else{if(JobOffer.status.type==='deleted'||JobOffer.status.type==='completed'||JobOffer.status.type==='timepass'||JobOffer.status.type==='nonWork'){return false}}


if(!user.uid){
//explanation ללא משתמש אפשר לראות רק מה שפנוי
if(!(JobOffer.status.type==='available'))return false
}else{
if(user.OccupationalGender=== "freelancer"){
  console.log("freelancer<user.OccupationalGender",user.OccupationalGender)
if(qs.parse(props.location.search).reduceBy==='archive'){
  //explanation  אם הפרילנסר מסתכל על הארכיון הוא יכול לראות רק את העבודות שהוא לקח וסיים
  if(!(((JobOffer.status.id===user.uid)) &&
   ((JobOffer.status.type==='completed')||(JobOffer.status.type==='Pending approval'))))
   {
    console.log("freelancerArchive<JobOffer.status.id: ",JobOffer.status.id)
    console.log("freelancerArchive<JobOffer.status.type: ",JobOffer.status.type)
    
    return false}
  
 
}else{
  if(qs.parse(props.location.search).reduceBy==='myJob'){
    if(!((JobOffer.status.id===user.uid)&&(JobOffer.status.type==='taken'))){ 
      
      return false}
  }else{
    //explanation משתמש תפוס לא יכול לראות עבודות
  if(!(user.statusAsFreelancer === "available")){
    console.log("user.statusAsFreelancer: ",user.statusAsFreelancer)
    return false}
  //explanation אם זה פרילנסר הוא יכול לראות רק מה שלא נלקח עדיין 
  if(!(JobOffer.status.type==='available')){return false}
  
  // אם אין נתוני דירוג למשתמש לא יוצגו עבודות ולמנוע קריסה בשורה שאח"כ
if (!(user.RatingAsFreelancer)){return false}
if (!(user.RatingAsFreelancer.rating)){return false}
// explanation אם זה פרילנסר הוא יכול לראות רק מה שמעל הניקוד שלו
if (user.RatingAsFreelancer.rating<JobOffer.level){return false}
}}}


if (user.OccupationalGender === "employer"){
  if(qs.parse(props.location.search).reduceBy==='archive'){
     if(JobOffer.status.type==='Pending approval')
  {return false}
  }
 
console.log("Notfreelancer<user.OccupationalGender",user.OccupationalGender)
if(!(JobOffer.uid === user.uid)){

  console.log("myjobs<getRelevantJobOffersAsArray<JobOffer.uid: ",JobOffer.uid)
  return false}
}
}

      return true
    })

console.log("relevantJobOffersAsArray:  ",relevantJobOffersAsArray)
console.log("MyJobs, JobOffersAsArray in fun",JobOffersAsArray)

  return relevantJobOffersAsArray

}


export default MyJobs;