import React, { useState, useContext } from 'react'
import dateFormat from 'dateformat'
import userContext from '../../context/user-context'
import {sendQuestion} from '../../firebase/DbConnection'
import Popup from '../Containers/Popup'



const AskUs = (props) => {
    const { user } = useContext(userContext);
    console.log(props, user)
    const [question, setQuestion] = useState('');

    const saveQuestion = () => {
        let inquiry = {
            dateTime: dateFormat(Date(), "mmm dd yyyy h:MM:ss TT Z"),
            qId: user.uid,
            jobId: props.jobId,
            question: question,
        }
        sendQuestion(props.employerId, user.uid, props.jobId, Date(), question)
        alert('the question was sent')
        // alert(`Call function dbConnection.sendQuestion with the following object as an argument:\n ${JSON.stringify(inquiry).replace(/,/g, '\n')}`)
        setQuestion('')
        props.setViewAsk(false)
    }

    const cancelQuestion = () => {
        setQuestion('')
        props.setViewAsk(false)
    }

    return (
        <Popup Title="Query" Body={
            <div className='question-container'>
                <p>Go ahead, ask us something about {props.jobTitle}</p>
                <form className="ask-question-form">
                    <textArea
                        rows="5" cols="30"
                        type="text"
                        className="question-text"
                        placeholder="Ask a question"
                        value={question}
                        onChange={e => setQuestion(e.target.value)}
                    />
                    <div className="qb-wrapper">
                        <button type="button"
                            className="question-button qb-save"
                            onClick={() => saveQuestion(question)}>
                            Save
                        </button>
                        <button type="button"
                            className="question-button qb-cancel"
                            onClick={cancelQuestion}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>} AllowClosure={false}
        />

    )
}

export default AskUs