import React, { useState, useEffect, useContext } from 'react'
import { getTree, getProjectsAsArray } from '../../firebase/DbConnection'
import Tree from 'react-vertical-tree'
import ConvertToTreeFormat from './fromFirebaseObjToTreeFormat'
// import Popup from '../Containers/Popup'
import ArchitectPopup from './ArchitectPopup'
import userContext from '../../context/user-context'
import values from '../../values'
import ErrorBoundary from '../Containers/ErrorBoundary'
import strings from '../../strings/navigator'
const ProjectTree = (props) => {

  const { user } = useContext(userContext);
  const [treeData, setTreeData] = useState([])

  // explanation:
  //   The file is used as a component (intended for all viewers)
  // Or as a standalone page (only for the architect himself)
  // Option 1: as a component - required to provide the aid (this is the architect's id)
  // And the pid (project id)
  // And the permission
  // Option 2: As Page: The id will be received from the user
  // And the pid from the address bar
  // (If the id is obtained from the user - that is, he is the architect himself and has all the permissions
  //  Only if the aip, pid values ​​are not accepted - in props -  the second option will be checked


  const id = props.aid ? props.aid : user.uid
  const pid = props.pid ? props.pid : props.match.params.id
  const permission = props.aid ? values.Permissions.READ_ONLY : values.Permissions.MANAGE

  const setData = (data) => { setTreeData(ConvertToTreeFormat(data, ["root"], pid)) }
  useEffect(() => { getTree(id, pid, setData) }, [])
  console.log("ProjectTree>TreeData:  ", treeData)

  // const setData=(data)=>{ setTreeData(ConvertToTreeFormat(data,["root"],props.match.params.id))}
  // useEffect(getTree(user.uid, props.match.params.id,setData),[])

  if (treeData === null) { return <div>nothing wet</div> }
  else {
    return <>
      <div className="project-tree-wrapper">

        <div className="tree-container">
          <div className="usage-info">
            {strings.woodArchitecture.firstTitleArchitectBoard}
            {/* To change the screen zoom: Press
          <span className="usage-bold">
              CTRL +
          </span>
          or
          <span className="usage-bold">
              CTRL -
          </span> */}

          </div>

          <div className="usage-terms">
            <div className="term-title">
            {strings.woodArchitecture.reader.title}
            </div>
            <div className="terms-description">
              <span style={{ color: 'red' }}> {strings.woodArchitecture.reader.red} </span>
              <span style={{ color: 'blue' }}>  {strings.woodArchitecture.reader.blue} </span>
              <span style={{ color: 'green' }}> {strings.woodArchitecture.reader.green}  </span>
              <span style={{ color: 'gray' }}>  {strings.woodArchitecture.reader.gray} </span>
            </div>
          </div>

          <div className="project-tree-internal-wrapper">
            <div className="project-tree">
              <ErrorBoundary>
                <Tree data={treeData} direction={false}
                  onClick={item => { }}
                  //   setOpenPopup(true)
                  //   setItem(item)
                  //   }}
                  render={item => <div><ArchitectPopup item={item} permission={permission} /></div>}
                /></ErrorBoundary>
            </div>
          </div>
        </div>

      </div>
    </>
  }
}

export default ProjectTree