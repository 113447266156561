import React, {useContext, useEffect, useState} from 'react';
import Popup from '../Containers/Popup'
import {getMessages} from '../../firebase/DbConnection'
import MessagesList from './MessagesList'
import userContext from '../../context/user-context'


const MessagesPopup=()=>{
   const {user} = useContext(userContext)
console.log("messages:user   ",user)
console.log("messages:user id   ",user.uid)


    // getMessages("FFdeuZrIiecdRRbs5FeBxag60Xk1")
    const [MessagesArray,setMessagesArray]=useState([])
    const [NewsCounter,setNewsCounter]=useState(0)
    
    const updateMessages=(messagesList,newsCounter)=>{
setMessagesArray(messagesList)
setNewsCounter(newsCounter)
    }
    console.log("MessagesArray:  messages  ",MessagesArray)
    
    useEffect(()=>{getMessages(user.uid,updateMessages)},[user.uid])
    const Body=<MessagesList messagesArray={MessagesArray} />
const ButtonText=NewsCounter?<div style={{color:'red'}}>messages+{NewsCounter}</div>:<div>messages</div>

    return(
    <Popup Title="your messages"  Body={Body} OpenPopupButtonText={ButtonText} />

    )
}

export default MessagesPopup