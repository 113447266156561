import React, { useState, Component } from 'react';
import Popup from './Popup'


const ObjPopup=({Title,OpenPopupButtonText,Obj})=>{
const BodyJsx=(<div>
 {Object.keys(Obj).map((key) => {
   return (<div>
   <br/>
    {key} &nbsp;: &nbsp; 
   {(typeof Obj[key] === 'object')?<ObjPopup Title={key} OpenPopupButtonText="For details click" Obj={Obj[key]}/>:Obj[key]}
   
   </div>
    )})}</div>)
        
return (<Popup Title={Title} Body={BodyJsx} OpenPopupButtonText={OpenPopupButtonText} /> )
}

export default ObjPopup