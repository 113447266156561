import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import DepositPopup from './DepositPopup'
import Dropdown from 'react-bootstrap/Dropdown'
import WithdrawalPopup from './WithdrawalPopup'
import AccountDashboard from './AccountPopup'

const AccountButton = () => {
  // todo: Compare the size of all the buttons
  // todo: Account data

  return (
    <DropdownButton className="dropdown d-inline-block account-buttons" id="dropdown-basic-button" title="Account" drop="up">
      <Dropdown.Item className="account-button"><DepositPopup /></Dropdown.Item>
      <Dropdown.Item className="account-button"><AccountDashboard /></Dropdown.Item>
      {/* <Dropdown.Item href="#/action-2">Account data</Dropdown.Item> */}
      <Dropdown.Item className="account-button"><WithdrawalPopup /></Dropdown.Item>
    </DropdownButton>
  )
}

export default AccountButton