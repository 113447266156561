import React from 'react'
import Explanation from '../BaseComponent/Explanation'


const DisplayBankForProfile = ({ bank, genderString }) => {
    console.log("DisplayBankForProfile>bank:  ", bank)
    const employerBankJsx = (<>

        <div className="line">
            <div className="line-head">
                Amount in the bank:
                </div>
            <div className="line-content">
                <Explanation text="An amount in the bank means the available money deposited in the system for future projects, usually - the higher the amount indicates greater investment intentions." />
                {bank.Available.total}
            </div>
        </div>

        <div className="line">
            <div className="line-head">
                Deposits:
                </div>
            <div className="line-content">
                <Explanation text="Refers to the amount deposited by the member so far" />
                {bank.Deposits.total}
            </div>
        </div>

        <div className="line">
            <div className="line-head">
                Payments:
                </div>
            <div className="line-content">
                <Explanation text="Payments - Aimed at amounts already transferred for project payments" />
                {bank.Payments.total}
            </div>
        </div>

        <div className="line">
            <div className="line-head">
                Outbound bonuses:
                </div>
            <div className="line-content">
                <Explanation text="Outbound Bonuses - means the amount of bonuses that was given by the employer. Sometimes can be used as sign of characterizing the employer's generosity" />
                {bank.OutboundBonuses.total}
            </div>
        </div>

        <div className="line">
            <div className="line-head">
                Bank Account Health Status:
                </div>
            <div className="line-content">
                {bank.status}
            </div>
        </div>
    </>
    )


    const freelancerBankJsx = (<>

        <div className="line">
            <div className="line-head">
                Profits:
                </div>
            <div className="line-content">
                <Explanation text="That is the amount the freelancer has earned so far. As the amount goes up indicates more good work he has done in the system" />
                {bank.Profits.total}
            </div>
        </div>

        <div className="line">
            <div className="line-head">
                Bank Account Health Status:
                </div>
            <div className="line-content">
                {bank.status}
            </div>
        </div>

    </>)

    return (
        <div>
            <h2>Bank</h2>
            {genderString === "asEmployer" ? employerBankJsx : freelancerBankJsx}
        </div>)


}

export default DisplayBankForProfile