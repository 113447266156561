import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const Popup = ({ Title, Body, Buttons = [], AllowClosure = true, CloseButtons = [], RemoteControl = [true, () => { }], CustomComponent = [], OpenPopupButtonText }) => {
  //    <Popup  Title="tttttttttt" Body="bbbbbbbbbbbb" ButtonText="btbtbt" ButtonFunction={fun} AllowClosure={true} />

  const [show, setShow] = useState(!OpenPopupButtonText);
  const [Control, setControl] = RemoteControl

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setControl(true)
  }

  return (
    <>
      {/* {OpenPopupButtonText&&<Button variant="primary" onClick={handleShow}>
              {OpenPopupButtonText}
            </Button>} */}

      {
        OpenPopupButtonText &&
        <Button className="popup-trigger-button" variant={OpenPopupButtonText.variant ? OpenPopupButtonText.variant : "primary"} onClick={handleShow}>
          {OpenPopupButtonText.text ? OpenPopupButtonText.text : OpenPopupButtonText}
        </Button>
      }

      <Modal show={show && Control} onHide={AllowClosure && handleClose} className="popup-wrapper">
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{Body}


        </Modal.Body>
        <Modal.Footer>


          {Buttons && Buttons.map(({ ButtonText, ButtonFunction }, i) => {
            // if(ButtonText==="component"){return ButtonFunction()}
            return (<Button key={i} variant="primary" onClick={ButtonFunction}>
              {ButtonText}
            </Button>)
          })}

          {CustomComponent.map((jsx, i) => {
            return (jsx)
          })}
          {CloseButtons.map(({ ButtonText, ButtonFunction }, i) => {
            // if(ButtonText==="component"){return ButtonFunction()}
            return (<Button key={i} variant="primary" onClick={() => {
              ButtonFunction()
              handleClose()
            }}>
              {ButtonText}
            </Button>)
          })}
          {AllowClosure && <Button variant="secondary" onClick={handleClose}>
            Close
                </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}




export default Popup