// import * as firebase from 'firebase';
import moment from 'moment';
import database from './firebase'
import shuffleArray from '../actions/shuffleArray'
import axios from 'axios'
import DBDirect from './DbConnectionDirect'


// write to db
const StopGetEmails=(uid)=>{
    console.log("DBC<StopGetEmail<uid: ",uid)
    axios({
        method: 'post',
        url: '/api/buttonNotToReceiveEmailsAnymore',
        data: { uid }
    })
}

// write to db
const RegisterNewJob = (uid,
    title,
    description,
    longDescription,
    level,
    date,
    amount,
    CloseToGroup,
    createAt,
    treeItem = false) => {
    return new Promise((resolve, reject) => {
        console.log("client>DBC>RegisterNewJob")

        axios({
            method: 'post',
            url: '/api/RegisterNewJob',
            data: {
                uid,
                title,
                description,
                longDescription,
                level,
                date: date.valueOf(),
                amount,
                CloseToGroup,
                createAt: createAt.valueOf(),
                status: { type: "available", id: null },
                treeItem
            }
        }).then(({ data }) => {
            const { key, e } = data
            if (key) resolve(key)
            reject(e)
        }).catch((e) => { reject(e) })
    })
}
// done
const GetJobOffersAsArray = (setJobOffersAsArray,uid="***************************") => {
    const doAfter = (jobArray) => {
        console.log("jobArray", jobArray)
        setJobOffersAsArray(jobArray)
    }
   console.log("Dbc<GetJobOffers<uid: ",uid)
   DBDirect.GetJobOffersAsArray(uid,doAfter)

}
// write to db
const UpdateOccupationalGender = (user, gender) => {
    const uid = user.uid
    console.log("client>DBC>UpdateOccupationalGender>user:  ", user)
    axios({
        method: 'post',
        url: '/api/UpdateOccupationalGender',
        data: { uid, gender }
    }).then(() => {}).catch((e) => { console.log("client>DBC>UpdateOccupationalGender>axios>catch>e:  ", e) })
}
const firstRegistration = (uid) => {
    axios({
        method: 'post',
        url: '/api/firstRegistration',
        data: { uid }
    }).then(() => { }).catch((e) => { console.log("client>DBC>firstRegistration>axios>catch>e:  ", e) })

}


// done -I think this is also the initial boot so you need to save the tada
const addRatingsToUser = (user, dispatch) => {
    const uid = user.uid
    axios({
        method: 'post',
        url: '/api/addRatingsToUser',
        data: { uid }
    }).then((val) => {
        if (!val) {

            dispatch({ type: 'ADD_VALUE_TO_USER', name: "RatingAsEmployer", value: 5 })
            dispatch({ type: 'ADD_VALUE_TO_USER', name: "RatingAsFreelancer", value: 3 })
        } else {
            dispatch({ type: 'ADD_VALUE_TO_USER', name: "RatingAsEmployer", value: val.data.asEmployer })
            dispatch({ type: 'ADD_VALUE_TO_USER', name: "RatingAsFreelancer", value: val.data.asFreelancer })

        }

    }).catch((e) => { console.log("client>DBC>addRatingsToUser>catch>e:  ", e) })


}



// done
const addOccupationalGenderToUser = (user, dispatch) => {
    
        DBDirect.addOccupationalGenderToUser(user, (value)=>{dispatch({ type: 'ADD_VALUE_TO_USER', name: "OccupationalGender", value })})         
        DBDirect.getStatusAsFreelancer(user.uid,(snapshot)=>{dispatch({ type: 'ADD_VALUE_TO_USER', name: "statusAsFreelancer", value: snapshot.val() === "available" ? "available" : "NotAvailable" })})
        }

        
// write to db
const SaveUserInformation = (user) => {
    console.log("user")
    axios({
        method: 'post',
        url: '/api/SaveUserInformation',
        data: { user }
    })
}

// write to db
const sendQuestion = (employerId, freelancerId, jobId, date, question) => {
    const data_question = {
        employerId,
        freelancerId,
        jobId,
        date,
        question
    }
    console.log("client>DBC>sendQuestion>question:  ", question)
    axios({
        method: 'post',
        url: '/api/sendQuestion',
        data: { data_question }
    })
}

// write to db
const setQuestionStatus = (eid, jid, qid, status) => {
    axios({
        method: 'post',
        url: '/api/setQuestionStatus',
        data: { eid, jid, qid, status }
    })
}

// write to db
const sendAnswer = (eid, jid, qid, answer, mid) => {
    console.log("client>DBC>sendAnswer>answer:  ", answer)
    axios({
        method: 'post',
        url: '/api/sendAnswer',
        data: { eid, jid, qid, answer, mid }
    })

}

// const getChat = (employerId, jobId, freelancerId, functionTodoAfterGettingData) => {
//     const doAfter = (val) => {
//         console.log("BBC>getChat>val: ",val)
//         if (val) {
//             const chatArray = [];
//             Object.keys(val).forEach(function (cid) {
               
//                 chatArray.push({ ...val[cid], cid })
//             })
//             functionTodoAfterGettingData(chatArray)
//         }
//     }
//     const data = { employerId, jobId, freelancerId }
//     poll("/api/getChat", data, doAfter)

// }

// write to db
const updateChatStatus = (employerId, jobId, freelancerId, sender) => {
    const data_chat = {
        employerId,
        jobId,
        freelancerId,
        sender
    }
    console.log("Dbc<updateChatStatus<employerId: ",employerId)
    console.log("Dbc<updateChatStatus<jobId: ",jobId)
    console.log("Dbc<updateChatStatus<freelancerId: ",freelancerId)
    console.log("Dbc<updateChatStatus<sender: ",sender)



    axios({
        method: 'post',
        url: '/api/updateChatStatus',
        data: { data_chat }
    })

}








// write to db
const updateChat = (employerId, jobId, freelancerId, text, sender) => {
    const data_chat = {
        employerId,
        jobId,
        freelancerId,
        text,
        sender
    }
    axios({
        method: 'post',
        url: '/api/updateChat',
        data: { data_chat }
    })

}
// write to db
const DBDeleteJob = (uid, jobId) => {
    
    // categoryLists()

    axios({
        method: 'post',
        url: '/api/DBDeleteJob',
        data: { uid, jobId }
    }).then(({ data }) => {
        const { key, e } = data
        if (key) { alert("the job was deleted") }
        else { alert("error, the job was not deleted: >>" + e) }
    }).catch((e) => { alert("error, the job was not deleted: >>" + e) })
}

// write to db
const addToGroup =(employerId ,freelancerId,groupName)=>{
   
console.log("dbc<addToGroup<groupName: ",groupName)
console.log("dbc<addToGroup<freelancerId: ",freelancerId)

    axios({
        method:'post',
        url:'/api/addToGroup',
        data:{employerId,freelancerId,groupName}
    })
}
// done
const getMyGroups =(employerId,updateGroups)=> {

    DBDirect.getMyGroups(employerId)
 .then((Groups)=>{
        console.log("Dbc<getMyGroups<GroupArr: ",Groups.data)
        updateGroups(Groups)
        
    })
}

// write to db
const applyToJob = (employerId, jobId, freelancerId,dispatch) => {
  return new Promise((resolve, reject)=>{
    axios({
        method: 'post',
        url: '/api/applyToJob',
        data: { employerId, jobId, freelancerId }
    }).then(({ data }) => {
        const { key, e } = data
        if (key) { 
            console.log("Dbc<applyToJob<key: ",data)
            alert("apply to job")
        dispatch({ type: 'ADD_VALUE_TO_USER', name: "statusAsFreelancer", value: "NotAvailable" })
        resolve()
    
    }
        else { alert("error, apply to job: >>" + e) 
        resolve()}
    }).catch((e) => { alert("error, apply to job: >>" + e)
    resolve() })
})
}


// write to db
const AcceptedTermsOfUse = (user, dispatch) => {
    const uid = user.uid
    axios({
        method: 'post',
        url: '/api/AcceptedTermsOfUse',
        data: { uid }
    })


    dispatch({ type: 'ADD_VALUE_TO_USER', name: "hasAcceptedTermsOfUse", value: true })
    localStorage.setItem('hasAcceptedTermsOfUse', true)


}
// done
const addAcceptTermsOfUseStatusToUser = (user, dispatch) => {
    const uid = user.uid
    
    
    DBDirect.addAcceptTermsOfUseStatusToUser(uid)
    .then((val) => {
        console.log("client>DBC>addAcceptTermsOfUseStatusToUser>axios>then>val:  ", val)

        dispatch({ type: 'ADD_VALUE_TO_USER', name: "hasAcceptedTermsOfUse", value: (val === true) })
        localStorage.setItem('hasAcceptedTermsOfUse', val === true)
    }).catch(e => console.log("client>DBC>addAcceptTermsOfUseStatusToUser>axios>catch>e:  ", e))
}

// done
const getUserById = (uid) => {
    return new Promise((resolve, reject) => {
   
        DBDirect.getUserNameById(uid)
     .then((user) => {                
              resolve(user.val())
            }).catch((e)=>{ reject("error>",e)})
           
        })
}

// done
const getUserImgById = (uid) => {
    return new Promise((resolve, reject) => {
   
        DBDirect.getUserImgById(uid)
     .then((user) => {         
              resolve(user.val())
            }).catch((e)=>{ reject("error>",e)})
           
        })
}

// write to db

const saveMessage = (userId = "Unknown", sender = "Unknown", context = "", content = "", text = "", value = "") => {
    const message = {
        userId,
        sender,
        context,
        content,
        text,
        value,
    }

    axios({
        method: 'post',
        url: '/api/saveMessage',
        data: { message }
    })
}
// write to db
const setMessageState = (uid, mid, value) => {
    const data_setMessageState = {
        uid,
        mid,
        value
    }
    axios({
        method: 'post',
        url: '/api/setMessageState',
        data: { data_setMessageState }
    })
}

// write to db
const updateEmployerRating = (eId, jId, fId, questionName, value) => {
    const data_updateEmployerRating = {
        eId,
        jId,
        fId,
        questionName,
        value
    }
    axios({
        method: 'post',
        url: '/api/updateEmployerRating',
        data: { data_updateEmployerRating }
    })
}

// write to db
const finishWork = (eId, jId, fId) => {
    const data_finishWork = {
        eId,
        jId,
        fId
    }
    axios({
        method: 'post',
        url: '/api/finishWork',
        data: { data_finishWork }
    
    }).then((v)=>{console.log("DBC>finishWork>then>v:  ",v)})
.catch((e)=>{console.log("DBC>finishWork>catch>e:  ",e)})
}

// write to db
const cancelWork = (eId, jId, fId, Reason) => {
    const data_cancelWork = {
        eId,
        jId,
        fId,
        Reason
    }
    axios({
        method: 'post',
        url: '/api/cancelWork',
        data: { data_cancelWork }
    })
}

// write to db
const saveReportingNonWork = (eId, jId, fId, Reporting) => {
    console.log("client>DBC> saveReportingNonWork>eId:  ", eId)
    console.log("client>DBC> saveReportingNonWork>fId:  ", fId)
    console.log("client>DBC> saveReportingNonWork>jId;  ", jId)
    const data_saveReportingNonWork = {
        eId,
        jId,
        fId,
        Reporting
    }
    axios({
        method: 'post',
        url: '/api/saveReportingNonWork',
        data: { data_saveReportingNonWork }
    }).then((data)=>{
        if(data){
            alert("The job remove to archive")
        }
    })
}

// write to db
const updateFreelancerRating = (eId, jId, fId, questionName, value) => {
    const data_updateFreelancerRating = {
        eId,
        jId,
        fId,
        questionName,
        value
    }
    axios({
        method: 'post',
        url: '/api/updateFreelancerRating',
        data: { data_updateFreelancerRating }
    })
}
// write to db
const jobCompletionApprove = (eid, jid, fid, amount) => {

    axios({
        method: 'post',
        url: '/api/jobCompletionApprove',
        data: { eid, jid, fid, amount }
    }).then(({ data }) => {
       console.log("dbc<jobCompletionApprove<data: ",data)
        const { key, e } = data
        if (key==="Completion Approve") 
        { 
             console.log("dbc<jobCompletionApprove<completion key: ",key)
            alert("job Completion Approve")
        }else
        if(key==="apply to group"){
            console.log("dbc<jobCompletionApprove<group key: ",key)
            alert("apply to group")
        }

        else { alert("error, job Completion Approve: >>" + e) }
    }).catch((e) => { alert("error, job Completion Approve: >>" + e) })

}
// write to db
const sendBonus = (eid, fid, amount, jid) => {
    axios({
        method: 'post',
        url: '/api/sendBonus',
        data: { eid, fid, amount, jid }
    }).then(({ data }) => {
        const { e } = data
        if (e)
            alert(e)
    }).catch((e) => { alert("error, the Bonus not send: >>" + e) })
}

const FreelancerRanksRefine = (eId, jId, fId, CurrentRating) => {

    axios({
        method: 'post',
        url: '/api/FreelancerRanksRefine',
        data: { eId, jId, fId, CurrentRating }
    })
}

// write to db
const EmployerRanksRefine = (eId, jId, fId, CurrentRating) => {

    axios({
        method: 'post',
        url: '/api/EmployerRanksRefine',
        data: { eId, jId, fId, CurrentRating }
    })
}
// done
const getMessages = (uid, updateMessages) => {
    const doAfter=(snapshot)=>{
        const val=snapshot.val()
        console.log("DBC<<get messages>val:  ", val)

        const messageArray = [];
        let NewsCounter = 0
        if (val) {
            Object.keys(val).forEach(function (mid) {
                messageArray.push({ ...val[mid], mid })
                if (val[mid].status === "unread") NewsCounter++
            })
        }
        //הפיכת המערך שהודעה חדשה תתוסף ראשונה
        const reversedMessageArray = messageArray.reverse();
        updateMessages(reversedMessageArray, NewsCounter)

    }
    
DBDirect.getMessages(uid,doAfter)
}

// --------------------------architect
// write to db
const addParent = (uid, title) => {

    axios({
        method: 'post',
        url: '/api/addParent',
        data: { uid, title }
    })
}
// done
const getTree = (uid, pid, setTree) => {

    const doAfter=(snapshot)=>{
const val=snapshot.val()
        if (val) {
            setTree(val)
            console.log("DBC>getTree>val:  ", val)
        }
    }
   
    DBDirect.getTree(uid, pid,doAfter)
    
}
// done
const getProjectsAsArray = (uid, setProjects)  =>(()=> {
        const doAfter=(snapshot)=>{
            const val=snapshot.val()
        const ProjectsAsArray = []
        const ProjectsAsObject = val

        if (!((ProjectsAsObject === undefined) || (ProjectsAsObject === null))) {
            Object.keys(ProjectsAsObject).map((key) => {
                const project = { id: key, name: ProjectsAsObject[key].title, lastUpdate: ProjectsAsObject[key].lastUpdate }
                ProjectsAsArray.push(project)
            })

            ProjectsAsArray.sort((a, b) => {

                if (new Date(a.lastUpdate) < new Date(b.lastUpdate)) 
                return 1
                else return -1
            })
        }
        console.log("DBC>>ProjectsAsArray:  ", ProjectsAsArray)

        setProjects(ProjectsAsArray)
    }
   
    DBDirect.getProjectsAsArray(uid,doAfter)
})


// write to db
const saveSubcomponent = (uid, path, subcomponent, isForUpdate) => {

    axios({
        method: 'post',
        url: '/api/saveSubcomponent',
        data: { uid, path, subcomponent, isForUpdate }
    })
}

// write to db
const DeleteArchitectComponent = (uid, item) => {

    axios({
        method: 'post',
        url: '/api/DeleteArchitectComponent',
        data: { uid, item }
    })
}

// write to db
const SignExecution = (uid, path, amount, level, item) => {
    console.log("DbC<SignExecution: ",uid, path)
    axios({
        method: 'post',
        url: '/api/SignExecution',
        data: { uid, path, amount, level, item }
    })
}

// write to db
const architectFinishReporting = (uid, item) => {
    axios({
        method: 'post',
        url: '/api/architectFinishReporting',
        data: { uid, item }
    })
}
// ----------------------end architect
// --------------------------profile
// write to db
const saveUserSelfDescription = (uid, Description) => {
    axios({
        method: 'post',
        url: '/api/saveUserSelfDescription',
        data: { uid, Description }
    })
}

// done חלקית
const getProfile = (ProfileOwnerId, gender, setProfileItem) => {

    let profile = {}


    // axios({
    //     method: 'post',
    //     url: '/api/googleGetProfile',
    //     data: { ProfileOwnerId }
    // })
    DBDirect.googleGetProfile(ProfileOwnerId)
    .then((googelData) => {
        if (googelData) {
            profile = { ...profile, displayName: googelData.displayName, photoURL: googelData.photoURL }

        }
        console.log("DBC>getProfile>googel>profile.data: ", profile)
        setProfileItem(profile)
    })

    // את שתי השיטות הבאות השארתי לביצוע מבסיס הנתונים כיוון זהם גם מבצעות
    axios({
        method: 'post',
        url: '/api/RegistrationGetProfile',
        data: { ProfileOwnerId }
    }).then((registrationData) => {
        if (registrationData.data.key) {
            profile = { ...profile, AuthenticationLevel: registrationData.data.key.AuthenticationLevel, description: registrationData.data.key.description }
        }

        console.log("DBC>getProfile>description>profile: ", profile)
        setProfileItem(profile)
    })

    axios({
        method: 'post',
        url: '/api/bankGetProfile',
        data: { ProfileOwnerId }
    }).then((Bank) => {

        const bank = Bank.data.key
        console.log("DBC>getProfile>bank>Bank: ", Bank)
        console.log("DBC>getProfile>bank>bank: ", bank)
        profile = { ...profile, bank }
        console.log("DBC>getProfile>bank>profile: ", profile)
        setProfileItem(profile)
    })
}
// done
const getRatings = (uid, genderString, doAfterGetting) => {
    // axios({
    //     method: 'POST',
    //     url: '/api/getRatings',
    //     data: { uid, genderString }
    // })
    
    DBDirect.getRatings(uid, genderString)
    .then((Rating) => {
    //    console.log("DBC>getRatings>Rating.data:  ", Rating.data.key)
        doAfterGetting(Rating)

    })
}

// ----------------end profile
// ----------------------bank
// todo?????
const VerifyFinancialAbility = (uid, sum) => {
    return new Promise((resolve, reject) => {

        axios({
            method: 'post',
            url: '/api/VerifyFinancialAbility',
            data: { uid, sum }
        }).then(({ data }) => {
            const { key, e } = data
            if (e) {
                reject(e)
            }
            else {
                console.log("the key1333333: ", +key)
                key == 1 ? resolve(true) : resolve(false)

                console.log("the key1: ", +key)
                console.log("the sum1: ", +sum)
            }

        }).catch((e) => {
            reject(e)
            console.log("the e: ", +e)
        })
    })
}

// const getExperienceSharingArray = (doAfterGetting) => {
//     axios({
//         method: 'get',
//         url: '/api/getExperienceSharingArray'
//     }).then((val) => {
//         doAfterGetting(shuffleArray(val.data))
//     }).catch((e) => { console.log("client>DBC>getExperienceSharingArray>catch>e:  ", e) })

// }

// -------------------------stripe
// const handleStripeToken = (token, amount, uid) => {
//     axios({
//         method: 'post',
//         url: '/api/stripe',
//         data: { ...token, amount, uid }
//     }).then(() => { }).catch((e) => { console.log("client>DBC>handleStripeToken>catch>e:  ", e) })

// }

// todo????
const getAccountDashboard =(uid)=>{
    return new Promise((resolve, reject) => {

    axios({
        method: "post",
        url: "/api/getBank",
        data: { uid }
        
    }).then((data)=>{
        console.log("Dbc<getAccountDashboard<data: ",data)
        resolve(data)
    }).catch((e)=>{ reject("error>",e)})

})
}

// const categoryLists = ()=>{
//     console.log("Dbc>categoryLists")
    

//     axios({
//         method: "post",
//         url: "/api/ListOfCommonProgrammingLanguages",
//         data: {  }
        
//     }).then((arr)=>{
//         if (arr){ console.log("Dbc>categoryLists>arr: ",arr)}
//        else{alert ("not arr")}
//     }).catch((e)=>(e))
    
// }



export {
    
    RegisterNewJob, GetJobOffersAsArray,
    SaveUserInformation, UpdateOccupationalGender, addOccupationalGenderToUser,

    sendQuestion,
    setQuestionStatus,
    sendAnswer,
    // getChat,
    updateChat,
    updateChatStatus,

    DBDeleteJob,
    addToGroup,
    getMyGroups,
    applyToJob,
    firstRegistration,
    addRatingsToUser,
    AcceptedTermsOfUse,
    addAcceptTermsOfUseStatusToUser,


    saveMessage, setMessageState, getMessages,
    updateEmployerRating,
    cancelWork,
    finishWork, jobCompletionApprove,
    saveReportingNonWork,
    updateFreelancerRating,
    FreelancerRanksRefine,
    EmployerRanksRefine,
    sendBonus,

    addParent, getTree, getProjectsAsArray, saveSubcomponent, DeleteArchitectComponent, SignExecution, architectFinishReporting,

    saveUserSelfDescription,
    getProfile,
    getRatings,
    VerifyFinancialAbility,
    // getExperienceSharingArray,
    // handleStripeToken,
    getUserById,getUserImgById,
    getAccountDashboard,
    StopGetEmails,
    // categoryLists
};