import React, { useState, useContext } from 'react';
import userContext from '../../context/user-context'
import '../../styles/components/_jobOfferListItem.scss';
import Buttons from "./Buttons"
import dateFormat from 'dateformat'
import AskUs from "./AskUs"
import Question from '../jobList/Question'
import ShowDetels from '../architect/ArchitectPopupComponents/ShowDetels'
import ProjectTree from '../architect/ProjectTree'
import values from '../../values'
import Spinner from '../BaseComponent/Spinner'
import strings from '../../strings/navigator'

const JobOfferListItem = (
    { title,
        description,
        longDescription,
        level,
        date,
        amount,
        status,
        questions,
        treeItem,
        uid,
        id,
        i,
    }
) => {
    const [viewLong, setViewLong] = useState(false)
    const [viewAsk, setViewAsk] = useState(false)
    const [viewQuestion, setViewQuestion] = useState(false)
    const [viewTree, setViewTree] = useState(false)
    const [Showspinner,setShowSpinner]=useState(false)
    console.log("JobOfferListItem> uid:  ", uid)
    const questionsAsArray = questions && Object.keys(questions).map(function (key) {
        return { qid: key, ...questions[key] }
    });
    console.log("job offer list item, questions:", questions)
    const showLong = () => {
        if (viewLong)
            return (
                <p className="offerList-long">longDescription: {  longDescription}</p>
            );
    };

    const relevantQuestions = questionsAsArray && questionsAsArray.filter((question) => {
        if (!question.answer) return false
        if (question.status === "deleted") return false
        return true
    })
    console.log("relevantQuestions:  ", relevantQuestions)
    const showAsk = () => {
        console.log('showAsk called')
        setViewAsk(true)
    }

    return (
        // <div className="jobListWrapper">
        <div className="job-list-wrapper">
            <div className="myJobOffers"
                style={{
                    "background-color": status.type === "taken" ? "#FFDFDC" : status.type === "Pending approval" ? "#E8FFDC" : "#DCF6FF"
                }}
            >
               {Showspinner&& <Spinner/>}
                <div className="job-title">
                    {title}
                </div>
                <div className="job-id">
                    Job Id #  {id}
                </div>
                <div className="job-body">
                    <ul className="body-content">
                        <li className="content-description">
                            <div className="body-title">
                                Description:
                            </div>
                            <p className="description-content">
                                {description}
                            </p>
                        </li>
                        {
                            viewLong ?
                                <li className="content-description">
                                    <div className="body-title">
                                        Long Description:
                                    </div>
                                    <p className="description-content">
                                        {viewLong ? longDescription : null}
                                    </p>
                                </li>
                                : null
                        }

                    </ul>
                    <div className="body-action">
                        <button
                            className="action"
                            type="button"
                            id="longBox-button"
                            onClick={() => setViewLong(!viewLong)}
                        >
                            {viewLong ?  (<div>{strings.viewMyJobs.hideLongDescription}</div>) : (<div>{strings.viewMyJobs.showLongDescription}</div>)}
                        </button>
                    </div>
                </div>
                {/* --------------------------- */}
                {treeItem && (
                    <div className="content-note">
                        Note! This work is part of a major work and is derived from the architecture tree
                        <br />
                        there are other details required to perform the work such as the stipulations as well as connections to other components of the project
                        <br />
                        Be sure to look at the definitions of the component in the tree before taking the job!
                        <br />

                        <div className="note-actions">
                            <ShowDetels item={treeItem} />
                            <button
                                className="action"
                                type="button"
                                id="treeBox-button"
                                onClick={() => setViewTree(!viewTree)}
                            >
                                {viewTree ? "Hide tree" : "Show tree"}
                            </button>
                        </div>

                        {
                            viewTree ?
                                <div className="note-tree">
                                    <ProjectTree aid={uid} pid={treeItem.spath.split('/')[0]} />
                                </div>
                                : null
                        }


                    </div>
                )}
                {/* -------------------- */}
                <br />
                <div className="offerList-question">
                    {(relevantQuestions && relevantQuestions.length > 0) && <button
                        type="button"
                        id="question-button"
                        onClick={() => setViewQuestion(!viewQuestion)}
                    >
                        {viewQuestion ? "Hide questions" : "Show questions & answers"}
                    </button>}
                    {/* </div>  */}
                    {viewQuestion ? <p>
                        {relevantQuestions.map((question) => { return <Question question={question} /> })}
                    </p> : null}
                </div>
                {/* -------------------- */}
                <div className="offerList-misc">
                    <div className="misc-container">
                        <span className="offerList-numbers"><span className="misc-caption">level:</span> {level}</span>
                        <span className="offerList-numbers"><span className="misc-caption">date:</span> {dateFormat(date, "longDate")}</span>
                        <span className="offerList-numbers"><span className="misc-caption">amount:</span> {amount}</span>
                        <span className="offerList-numbers"><span className="misc-caption">status:</span> {status.type}</span></div>
                    <div className="offerList-buttons" >
                        <Buttons status={status} showAsk={showAsk} id={id} title={title} employerId={uid} setShowSpinner={setShowSpinner}/>
                    </div>
                </div>
                <br />
                {viewAsk ?
                    <AskUs
                        employerId={uid}
                        jobId={id}
                        jobTitle={title}
                        setViewAsk={setViewAsk}
                    /> : null}

            </div></div>
    )
}

export default JobOfferListItem