import React from 'react'

export const GeneralQuestions=[
    {Header:<h1>How Do I Change my description on my profile?</h1>,
        Body:(<h2>1. click on the button "see profile" in the header to be taken to your personal profile.<br/>
        2. Click on 'edit profile description' 3. Write your new description in the in the box.<br/>
        4. click on the save button,<br/>
        5. Click on the 'Refresh Profile' button to be shown your updated profile</h2>)},

    {Header:<h1>How can I check my account balance? </h1>,
         Body:(<h2>1. Click on the – 'account' button located on the taskbar,<br/>
         2. Click on the 'Account Dashboard' button<br/>
          3. You will now be able to see your  account balance as well as your bank details</h2>)},

    {Header:<h1>How Do I Add Money To My Account?</h1>,
        Body:(<h2> 1.  Click on the –' account' button in the taskbar,<br/>
            2.  Click on the 'deposit' button.<br/>
            3. You now have the  option to make a deposit and pay by credit card.</h2>)},

     {Header:<h1>How do I get support?</h1>,
        Body:(<h2> First look for the question in Q&A if you have not found an answer to your question you can contact support by clicking the SUPPORT button</h2>)},

     {Header:<h1>How Do I Withdraw Money From My DirectDone Account?</h1>,
         Body:(<h2> Click on the - account button located in the taskbar,<br/>
            Click on the withdrawal button,<br/>
            fill in the relevant details on the form.</h2>)},

    {Header:<h1>What Are The Fees For The Service?</h1>,
        Body:(<h2>nor project execution!<br/>
            There are only fees on deposit and withdrawal<br/>
            3% deposit fee<br/>
            10% withdrawal fee<br/>
            This means a freelancer who earned $ 500 and then, as a contractor used their account balance to hire other freelancers will not pay any fees!<br/>
            Minimum withdrawal or deposit $ 100<br/>
            Fees and conditions may change from time to time and are subject to the decision of the system</h2>)},




]


export const EmployerQuestions=[
    {Header:<h1>Why Can't I See A Job I Put In The Repository?</h1>,
        Body:(<h2>There can be several reasons:<br/>
            The first reason you did not log in as a user  - you have to login first<br/>
            to be  be able to see your jobs<br/>
            Another reason can be that you are defined as a freelancer and not as an employer-<br/>
            you need to change your occupational definition to employer on the top right of page.)<br/>
            In addition, it is possible that the work was archived for several reasons:<br/>
            * Expired - each job has a deadline that you set when the job was submitted - Outdated work is automatically archived.<br/>
            * You deleted the job.<br/>
            * The job is complete - the freelancer has finished it and you have approved it.</h2>)},

    {Header:<h1>How Do I Create An Entire Project With Several Jobs?</h1>,
         Body:(<h2>Go to the architect-board page and click on create a new project which opens a new page where a tree is created,<br/>
            Build your project by clicking on the existing tree<br/>
            and adding subproject to the desired main project.<br/> 
            It is possible to add several subprojects to a main project and add even further tasks to each sub-project, thus creating a system where even a sub-project is a project in itself.</h2>)},

    {Header:<h1>How Can I See If The Work Has Been Added To The Job Repository?</h1>,
        Body:(<h2>Go to the 'My job' page and see if it exists in 'my job repository'</h2>)},

     {Header:<h1>What Do The Frame Colors Of The Jobs Mean?</h1>,
        Body:(<h2>Red - the work was taken, Green - The freelancer has finished the job and the job is waiting for your approval and rating.</h2>)},

     {Header:<h1>Why is no one interested in the job I posted?</h1>,
         Body:(<h2>There can be several reasons for this:<br/>
            - wording and characterization - a job that is not well worded or not properly characterized might discourage freelancers from taking it. For help in characterizing the work you can contact customer service(paid service).<br/>
            -price - a low price in relation to the requirements will result in a low response,<br/>
            - rating - requiring a very high rating significantly reduces the number of freelancers who can take the job.<br/>
            -Your profile rank -  an employer who is ranked high by the freelancers and an employer who often gives Bonuses and the like will be preferred by freelancers."</h2>)},

    {Header:<h1>How Do I Post A New Job?</h1>,
        Body:(<h2>You need to access the "Post new job" page by clicking on the bar above,<br/>
        then you will be presented with a form for filling in the data of the new job. Fill in as desired and confirm at the end by clicking on 'add job'</h2>)},

     {Header:<h1>How Can I See Work Completed In The Past?</h1>,
        Body:(<h2>It is in your archive page.</h2>)},

     {Header:<h1>How Do I Transfer The Payment To A Freelancer?</h1>,
         Body:(<h2>the job and rating the freelancer<br/>
            -then the payment will be transferred to the freelancer.<br/>
            Please note:<br/>
            If you do not report the work receipt in a week -<br/>
            the money will be automatically transferred to the freelancer</h2>)},

 {Header:<h1>I'm not happy With the work I received - What Should I Do?</h1>,
    Body:(<h2>First of all we want you to know that we are sorry to hear this and with that we ask you to report this to us - so that we can follow up accordingly with the freelancer.<br/>
Here are some ways in which you can act:<br/>
First option is just to report it - in this case we will include your negative experience in the rating of the freelancer, and  the freelancer will still receive the payment for the completed work and will be able to take jobs from you later as well.<br/>
Another option is to put him on the blacklist - in this case he will not be able to take more jobs that you post.<br/>
In the worse case - you can file a labor dispute and ask that his payment be withheld. In this case the matter will be examined by a professional panel that will decide whether he deserves payment for his work. The panel's decisions are final.<br/>
All of these options are available to you in the the Job Completion Report. You can also contact support if needed</h2>)},
    
    


]

export const FreelancerQuestions=[
    {Header:<h1>After I Took A Job - A Message Was Displayed "No Relevant Job Openings For You" -Why is that?</h1>,
        Body:(<h2> You can not take more than one job at a time, after finishing the current job you can see and take more jobs.       </h2>)},

    {Header:<h1>How Can I See The Details of the Job i Took?</h1>,
         Body:(<h2>The details of the job you took can be found under 'My Jobs'</h2>)},

    {Header:<h1>What Do The Colors Of The Frames mean?</h1>,
        Body:(<h2>Red- the work was taken by you. Green- You have finished the work and the work is awaiting approval and rating by the employer.</h2>)},

     {Header:<h1>I Finished The Work What should I Do?</h1>,
        Body:(<h2>You need to rate your satisfaction with the employer using our feedback questionnaire. <br/>
            1.Click  on the page 'my job' where the job is displayed .2.Click on the button<br/>
            'I finished the job' 3. You will  then be presented with the questions to rate them.</h2>)},

     {Header:<h1>I Cannot Do The Job What Should I Do?</h1>,
         Body:(<h2>Go to the 'my job' page where the job is displayed,<br/>
         then click on the Cancel job button,<br/>
         and then click on the 'I accidentally took a job I cannot do' button and finally click on 'OK'</h2>)},

    {Header:<h1>Why Can't I  Take A Job?</h1>,
        Body:(<h2>There can be several reasons:<br/>
            * You did not log in to the site as a user, you must first login,<br/>
            * You are defined as a employer and not as an freelancer-   you need to change your occupational definition to freelancer by clicking the switch on  the top right of the page<br/>
            * You already have a job assigned to you. You can only take on one job at a time so it is not possible to take on  another job until you have completed the first one</h2>)},

     {Header:<h1>I Do Not See Any Jobs At All. I Just Get A Message That There Are No Relevant Jobs For Me -why?</h1>,
        Body:(<h2> It could simply be because  there are no suitable jobs in the repository for you. <br/>
            This might be because you have a low rating and are thus excluded from jobs that have a higher rating.<br/>
            Another reason you might not be seeing any jobs is because  you already have a job assigned to you.<br/>
            You can only take on one job at a time so it is not possible to take on  another job until you have completed the first one</h2>)},

     {Header:<h1>Why Can I See Job Openings The System Isn't Letting Me Take Them?</h1>,
         Body:(<h2>You need to be logged in as a user in order to take jobs.</h2>)},

     {Header:<h1>Should I take a job if I do not understand the requirement of the employer?</h1>,
         Body:(<h2>NO! You should only take jobs that you are well worded and you understand what is required of you.<br/>
              Otherwise you risk not doing the job well which will affect your rankings, reducing your chance of getting better jobs down the line. </h2>)},

     {Header:<h1>How Can I See Works I Have Done In A Past?</h1>,
         Body:(<h2>Look in the archive.</h2>)},

          {Header:<h1>I Finished The Job- When Will I Get The Payment?</h1>,
         Body:(<h2>The payment will be transferred to you once the employer confirms that he received the job. If the employer does not respond within a week, we will send you the money automatically. </h2>)},

] 

// {
//     question1:{
//         Question:"How Do I Change my description on my profile?",
//         Answer:`1. click on the button "see profile" in the header to be taken to your personal profile.${<br/>}
//         2. Click on 'edit profile description' 3. Write your new description in the in the box.${<br/>}
//         4. click on the save button,${<br/>}
//         5. Click on the 'Refresh Profile' button to be shown your updated profile `
//     },
//     question2:{
//         Question:"",
//         Answer:``
//     },
//     question3:{
//         Question:"",
//         Answer:``
//     },
//     question4:{
//         Question:"",
//         Answer:``
//     },
//     question5:{
//         Question:"",
//         Answer:``
//     },
//     question6:{
//         Question:"",
//         Answer:``
//     },
//     question7:{
//         Question:"",
//         Answer:``
//     }   
// }


// export const EmployerQuestions={
    
//     question1:{
//         Question:"",
//         Answer:``
//     },
//     question2:{
//         Question:"",
//         Answer:``
//     },
//     question3:{
//         Question:"",
//         Answer:``
//     },
//     question4:{
//         Question:"",
//         Answer:``
//     },
//     question5:{
//         Question:"",
//         Answer:``
//     },
//     question6:{
//         Question:"",
//         Answer:``
//     },
//     question7:{
//         Question:"",
//         Answer:``
//     } 
// }

// export const FreelancerQuestions={
    
//     question1:{
//         Question:"",
//         Answer:``
//     },
//     question2:{
//         Question:"",
//         Answer:``
//     },
//     question3:{
//         Question:"",
//         Answer:``
//     },
//     question4:{
//         Question:"",
//         Answer:``
//     },
//     question5:{
//         Question:"",
//         Answer:``
//     },
//     question6:{
//         Question:"",
//         Answer:``
//     },
//     question7:{
//         Question:"",
//         Answer:``
//     } 
// }