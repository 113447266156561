import React, { useContext } from 'react'
import userContext from '../../context/user-context'
import { getUserById } from '../../firebase/DbConnection';
import moment from 'moment'
import ObjPopup from '../Containers/ObjPopup'


const AccountItem = ({ name, obj }) => {


    let objForDisplay = {}
    if (name === "Bonuses") {

        obj && (Object.keys(obj).map((key, i) => {

            if (typeof obj[key] === 'object') {

                const bonuseName = "bonuse number " + (i + 1) + "  :  amount  " + obj[key].amount + "$"
                objForDisplay[bonuseName] = {}
                objForDisplay[bonuseName].amount = obj[key].amount + "$"

                console.log("AccountDashboard>key: ", key)
                const name = "employer name"

                getUserById(obj[key].employerId).then((userName) => { objForDisplay[bonuseName][name] = userName })

                objForDisplay[bonuseName].date = moment(obj[key].date).format('dddd MMMM Do YYYY HH:mm')
            }

            console.log("AccountDashboard>obj: ", obj)

        }))
        if (obj && obj.total) { objForDisplay.total = obj.total + "$" }
    }
    if (name === "Deposits") {
        obj && (Object.keys(obj).map((key, i) => {

            if (typeof obj[key] === 'object') {
                const depositsName = "deposits number " + (i + 1) + "  :  amount  " + obj[key].amount + "$"
                objForDisplay[depositsName] = {}
                objForDisplay[depositsName].amount = obj[key].amount + "$"
            }
        }))
        if (obj && obj.total) { objForDisplay.total = obj.total + "$" }
    }

    if (name === "OutboundBonus") {
        obj && (Object.keys(obj).map((key, i) => {
            if (typeof obj[key] === 'object') {
                const OutboundBonusName = "bonuse number " + (i + 1) + "  :  amount  " + obj[key].amount + "$"
                objForDisplay[OutboundBonusName] = {}
                objForDisplay[OutboundBonusName].amount = obj[key].amount + "$"

                const name = "freelancer name"
                getUserById(obj[key].freelancerId).then((userName) => { objForDisplay[OutboundBonusName][name] = userName })

                objForDisplay[OutboundBonusName].date = moment(obj[key].date).format('dddd MMMM Do YYYY HH:mm')
            }
        }))
        if (obj && obj.total) { objForDisplay.total = obj.total + "$" }
    }
    if (name === "Payments") {
        obj && (Object.keys(obj).map((key, i) => {
            if (typeof obj[key] === 'object') {
                const PaymentsName = "bonuse number " + (i + 1) + "  : amount  " + obj[key].amount + "$"
                objForDisplay[PaymentsName] = {}
                objForDisplay[PaymentsName].amount = obj[key].amount + "$"

                objForDisplay[PaymentsName].date = moment(obj[key].date).format('dddd MMMM Do YYYY HH:mm')
                console.log("obj[key].jid: ", obj[key].jid)
                const jobName = "job name"
                objForDisplay[PaymentsName][jobName] = GetJobNameById(obj[key].jobId)
                console.log("AccountItem>getJobNameById:  ", GetJobNameById(obj[key].jobId))

            }
        }))
        if (obj && obj.total) { objForDisplay.total = obj.total + "$" }
    }
    if (name === "Profits") {
        obj && (Object.keys(obj).map((key, i) => {
            if (typeof obj[key] === 'object') {
                const ProfitsName = "job name  : " + GetJobNameById(key) + " : amount  " + obj[key].amount + "$"
                objForDisplay[ProfitsName] = {}
                objForDisplay[ProfitsName].amount = obj[key].amount + "$"

                objForDisplay[ProfitsName].date = moment(obj[key].date).format('dddd MMMM Do YYYY HH:mm')

                console.log("GetJobNameById(obj[key].jobId): ", GetJobNameById(key))
            }
        }))
        if (obj && obj.total) { objForDisplay.total = obj.total + "$" }
    }

    console.log("AccountDashboard>name: ", name)



    return <h2 className="dashboard-popup-item">
        {name} : {obj && <span className="item-value"> {obj.total + " $"} </span>} <div className="item-spacer" /> {objForDisplay && <ObjPopup Title={name} OpenPopupButtonText="Details" Obj={objForDisplay} />}
    </h2>
}


const GetJobNameById = (jid) => {
    let jobName = "not found"
    const { JobOffersAsArray } = useContext(userContext);
    JobOffersAsArray.map((job) => {

        if (job.id === jid) {
            jobName = job.title
        }
    })
    return jobName
}


export default AccountItem