import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { startLogout, startLogin } from '../../actions/auth';
import userContext from '../../context/user-context'
import { firebase, googleAuthProvider } from '../../firebase/firebase';
import { NavLink } from 'react-router-dom';
import UserPicture from '../BaseComponent/UserPicture'
import ChooseGender from './ChooseGender';
import RequireApprovalMessage from '../termsOfUse/RequireApprovalMessage'
import Phonebook from '../chat/Phonebook'
import WorkFinishingProcedure from '../finishJob/WorkFinishingProcedure'
import MessagesPopup from '../Messages/Messages'
import ProfilePopup from '../profile/ProfilePopup'
import SupportPopup from '../support/SupportPopup'
import AccountButton from '../account/AccountButton'
import logo from '../../images/logo.png'

import Sticky from 'react-stickynode';
import Popup from '../Containers/Popup'
import strings from '../../strings/navigator'

const Header = () => {

  const [showSidebarMenu, setMenuState] = useState(false);

  
  const { user } = useContext(userContext);
  console.log("Header>user:  ", user)
  const isEmployer = user.OccupationalGender === "employer"

  const loging = user.uid ? startLogout : startLogin;
  const logingMassege = user.uid ? "Logout" : "Login";
  const wellcomeMassege = user.uid ? "wellcome " + user.displayName : ""
  
  return (
    <header className="header">
      <div className="content-container">
        <div className="header__content">
          <Link className="header__title" to="/">
            <div className="logo">
              <img className="logo-image" src={logo} alt="logo" />
              <span className="logo-text">
                <h1 className="logo-main">
                  direct done
                  </h1>
                <div className="logo-sub">
                  Do it direct
                  </div>
              </span>
            </div>
          </Link>
          
         
         

          <div className="links sm-hidden-content">
            {user.uid && isEmployer && <NavLink to="/AddNewJob" className="button button--link">{strings.header.postNewWork}</NavLink>}
            {user.uid && isEmployer && <NavLink to="/MyJobs" className="button button--link">{strings.header.viewMyJobs}</NavLink>}
            {user.uid && isEmployer && <NavLink to="/ArchitectBoard" className="button button--link">{strings.header.architectBoard}</NavLink>}
            {(!user.uid || !isEmployer) && <NavLink to="/jobOffers" className="button button--link">See job offers</NavLink>}
            {user.uid && isEmployer && <NavLink to="/MyJobs?reduceBy=archive" className="button button--link">archive</NavLink>}
            {user.uid && !isEmployer && <NavLink to="/jobOffers?reduceBy=archive" className="button button--link">archive</NavLink>}
            {user.uid && !isEmployer && <NavLink to="/jobOffers?reduceBy=myJob" className="button button--link">my job</NavLink>}
            <NavLink to="/supportAccordion" className="button button--link">Q&A</NavLink>
            {(!user.uid || !isEmployer) && <NavLink to="/" className="button button--link">back to home page</NavLink>}
          </div>
          <span className="spacer"></span>
          <div className="button-action-wrapper sm-allowed-content" onClick={() => setMenuState(!showSidebarMenu)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg>
          </div>
          <div className="choose-gender sm-hidden-content">
            <ChooseGender />
          </div>
        </div>
        <div className="sub_header__content sm-hidden-content">
        
          <span className="userName">
            {user.uid && <UserPicture />}
            <h1 className="header__title-message">
              {wellcomeMassege}
            </h1>
          </span>

           <span className="spacer"></span>
          <ul className="actions">
            <li className="action">
              <RequireApprovalMessage />
            </li>
            {
              user.uid &&
              <li className="action">
                <MessagesPopup />
              </li>
            }
            {
              user.uid &&
              <li className="action">
                <ProfilePopup ProfileOwnerId={user.uid} gender={user.OccupationalGender === "employer" ? "asEmployer" : "asFreelancer"} />
              </li>
            }
             {
              user.uid &&
            <li className="action">
              <AccountButton />
            </li>}
            <li className="action">
              <SupportPopup />
            </li>
            <li className="action">
              <button className="button button--link logout" onClick={loging}>
                {logingMassege}
              </button>
            </li>
          </ul>

        </div>

      </div>
      {
        showSidebarMenu &&
        <div className="sidebar-header">

          <div className="header-app-logo">
            <Link className="header__title" to="/">
              <div className="logo">
                <img className="logo-image" src={logo} alt="logo" />
                <span className="logo-text">
                  <h1 className="logo-main">
                    direct done
                  </h1>
                  <div className="logo-sub">
                    Do it direct
                  </div>
                </span>
              </div>
            </Link>
            <div className="menu-close-action">
              <div className="button-action-wrapper" onClick={() => setMenuState(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="user-type-selection">
            <div className="choose-gender">
              <ChooseGender />
            </div>
          </div>

          <ul className="sidebar-items-container">

            <li className="sidebar-item user-info-item">
              <span className="userName">
                {user.uid && <UserPicture />}
                <h1 className="header__title-message">
                  {wellcomeMassege}
                </h1>
              </span>
            </li>

            
            <li className="sidebar-item">
              <RequireApprovalMessage />
            </li>
            

            {/* page links */}
            {
              user.uid && isEmployer &&
              <li className="sidebar-item">
                <NavLink to="/AddNewJob" className="button button--link" onClick={()=>{setMenuState(false)}}>Broadcast a new job</NavLink>
              </li>
            }
            {
              user.uid && isEmployer &&
              <li className="sidebar-item">
                <NavLink to="/MyJobs" className="button button--link" onClick={()=>{setMenuState(false)}}>View my jobs</NavLink>
              </li>
            }
            {
              user.uid && isEmployer &&
              <li className="sidebar-item">
                <NavLink to="/ArchitectBoard" className="button button--link" onClick={()=>{setMenuState(false)}}>architect-Board</NavLink>
              </li>
            }
            {
              (!user.uid || !isEmployer) &&
              <li className="sidebar-item">
                <NavLink to="/jobOffers" className="button button--link" onClick={()=>{setMenuState(false)}}>See job offers</NavLink>
              </li>
            }
            {
              user.uid && isEmployer &&
              <li className="sidebar-item">
                <NavLink to="/MyJobs?reduceBy=archive" className="button button--link" onClick={()=>{setMenuState(false)}}>archive</NavLink>
              </li>
            }
            {
              user.uid && !isEmployer &&
              <li className="sidebar-item">
                <NavLink to="/jobOffers?reduceBy=myJob" className="button button--link" onClick={()=>{setMenuState(false)}}>my job</NavLink>
              </li>
            }
            {
              user.uid && !isEmployer &&
              <li className="sidebar-item">
                <NavLink to="/jobOffers?reduceBy=archive" className="button button--link" onClick={()=>{setMenuState(false)}}>archive</NavLink>
              </li>
            }
            
            
            
            {/* action buttons */}
            {
              user.uid &&
              <li className="sidebar-item">
                <MessagesPopup />
              </li>
            }
            {
              user.uid &&
              <li className="sidebar-item">
                <AccountButton />
              </li>
            }
            {
              user.uid &&
              <li className="sidebar-item">
                <ProfilePopup ProfileOwnerId={user.uid} gender={user.OccupationalGender === "employer" ? "asEmployer" : "asFreelancer"} />
              </li>
            }
            {
              true &&
              <li className="sidebar-item">
                <NavLink to="/supportAccordion" className="button button--link" onClick={()=>{setMenuState(false)}}>Q&A</NavLink>
              </li>
            }
            <li className="sidebar-item">
              <SupportPopup />
            </li>
            {
              true &&
              <li className="sidebar-item">
                <NavLink to="/" className="button button--link" onClick={()=>{setMenuState(false)}}>back to home page</NavLink>
              </li>
            }
            <li className="sidebar-item">
              <button className="button button--link logout" onClick={loging}>
                {logingMassege}
              </button>
            </li>
          </ul>

        </div>
      }
    </header>
    
  );
}

export default Header;