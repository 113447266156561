import React, { useContext, useState } from 'react'
import userContext from '../../context/user-context'
import { sendAnswer, setQuestionStatus } from '../../firebase/DbConnection'
import { Button } from 'react-bootstrap'


const Question = (props) => {
    const [answer, setAnswer] = useState("")
    const { user, JobOffersAsArray } = useContext(userContext)
    const qid = props.match.params.qid
    const onDataChange = (e) => {

        setAnswer(e.target.value)

    }

    const postAnswer = () => {
        sendAnswer(user.uid, jid, qid, answer)

        alert("Thanks for your response")
    }

    const deleteQuestion = () => {
        setQuestionStatus(user.uid, jid, qid, "deleted")
        alert("question is deleded")
    }

    if (!user.uid) return (<h1>you have to login</h1>)


    if (!(JobOffersAsArray.length)) return (<h1>The data is loaded, please wait</h1>)

    console.log(" Question>JobOffersAsArray:  ", JobOffersAsArray)

    const jobs = JobOffersAsArray.filter((job) => job.questions && job.questions[qid])
    console.log(" Question>jobs:  ", jobs)

    if (!jobs[0]) return (<h1>The data is loaded, please wait</h1>)

    const questionO = jobs[0].questions[qid]
    const jid = jobs[0].id

    return (<div>
        <h1>
            {/* about:
    { jobs[0].title} */}
            <br />
   content:
    {questionO.question}
            <br />
   your unswer:<br />
            <textarea
                rows="8" cols="80"
                className='answer'
                value={answer}
                onChange={onDataChange}
            ></textarea>
            <br /></h1>

        <Button onClick={postAnswer}>send unswer</Button>&nbsp;
        <Button className="deleteButton" onClick={deleteQuestion}>delete question</Button>&nbsp;

    </div>


    )

}
export default Question