import React, {useContext,useState} from 'react'
import userContext from '../../context/user-context'
import LevelBar from '../BaseComponent/LevelBar'
import {updateEmployerRating, finishWork, EmployerRanksRefine} from '../../firebase/DbConnection'



const FreelancerTerminationChainDefinition=(addToIndex,eId,jId,fId)=>{
    
    const {dispatch}=useContext(userContext)
const [level,setLevel]=useState(-1)
const [description,setDescription]=useState()
const [Rating,setRating]=useState(0)
const onDescriptionChange=(e)=>{
    setDescription(e.target.value)
}
const PerformLevel=(questionName)=>{
if (level<0 || level>10){alert("the level must be between 0 to 10")}
else{
    updateEmployerRating(eId,jId,fId,questionName,level)
    setLevel(-1)
    addToIndex(1)
    setRating(Rating+level/5)
}       
}
const foreword={
    Title:"Finish Work",
 Body:<h1>We are glad you finished the job and hope you had a pleasant experience,<br/>
 Please answer some questions <br/>
 These questions help us to best fit an employer to a freelancer<br/>
 Please take our questions seriously
 Please consider your rating carefully - your rating can have a significant impact on your employer
 <div style={{backgroundColor: "#FF69B4"}}>Please note: Only after answering the questions the work will be listed as completed</div>
 </h1>,
 Buttons:[{
ButtonText:"continue", 
ButtonFunction:()=>{
    addToIndex(1)
   }}], 
AllowClosure:false ,
OpenPopupButtonText:"I finished the job" }

const CommunicationQuestion={
 Title:"Communication with the employer",Body:<div>
     <h1>How was the communication with the employer?<br/>
 Is this employer available for questions?<br/>
 Did he explain his intention clearly?<br/>
 Was it pleasant to work with him?<br/>
 <h6>
        Rating values:
        0- unbearable
        3- Worse
        5- Reasonable
        7- Good
        8-Very good
        9 - Excellent
        10- Excellent ++
        </h6>
 <LevelBar level={level} setLevel={setLevel}/>
 </h1>
 </div>,
 Buttons:[{
 ButtonText:"Send reply and continue",
  ButtonFunction:()=>{PerformLevel("Communication")}}],
   AllowClosure:false}

   const PriceQuestion={Title:<h1>Wage Fairness</h1>,
    Body:<h1>Was the salary fair compared to the work required to do?
        <h6>
        Rating values:
        2- preposterous
        3- Unfair
        5- fair
        7- Definitely fair
        9- Above and beyond
        </h6>
        <LevelBar level={level} setLevel={setLevel}/></h1>,
        Buttons:[{
        ButtonText:"Submit a salary assessment and continue", 
        ButtonFunction:()=>{PerformLevel("Wage Fairness")}}], 
        AllowClosure:false}

        const AdditionsQuestion={Title:<h1>changes and additions</h1>,
            Body:<h1>Have there been changes and additions during the work (after first consent)?
                <h6>
                Changes meant that the employer asked for one thing at first and then changed its requirements (but not if the job was done incompletely or inaccurately at first and the employer required to complete or change)
                Rating values:
                1- The employer used me unfairly
                3- There were too many changes and additions
                5- There were reasonable changes and additions
                7- There were only minor changes and additions
                9- No changes and additions were required at all
        </h6>
            <LevelBar level={level} setLevel={setLevel}/></h1>,
            Buttons:[{
            ButtonText:"I rank, continue", ButtonFunction:()=>{PerformLevel("changes and additions")}}],
             AllowClosure:false}

            const WordingQuestion={Title:<h1>wording of the work</h1>,
                Body:<h1>Was the wording of the work clear and understandable?
                Were job requirements well drafted?
                <h6>
                Rating values:
                1- Not understood
                3- Not clear enough
                5- Reasonable
                7- Understandable
                9- Incredibly clear
        </h6>
            <LevelBar level={level} setLevel={setLevel}/></h1>,
            Buttons:[{
             ButtonText:"I answered, continue", ButtonFunction:()=>{PerformLevel("wording")}}], 
             AllowClosure:false}

             const GeneralQuestion={Title:<h1>general grade</h1>,
                Body:<h1>Please give a general grade for your collaborative work experience!
                <h6>
                Rating values:
                1- Disaster
                5- Good
                9- Excellent
        </h6>
            <LevelBar level={level} setLevel={setLevel}/></h1>,
            Buttons:[{
             ButtonText:"I answered, continue", ButtonFunction:()=>{PerformLevel("general grade")}}], 
             AllowClosure:false}

             const VerbalDescription={Title:<h1>verbal description</h1>,
                Body:<h1>Please add a verbal description to the job experience you had with the employer
                    <textarea
          rows="8" cols="30"
            placeholder="verbal description to your job experience"
            value={description}
            onChange={onDescriptionChange}
          ></textarea>
            </h1>,
            Buttons:[{
             ButtonText:"I answered, continue", ButtonFunction:()=>{
                 if(description){
                     updateEmployerRating(eId,jId,fId,"verbal description",description)
                    }
                    finishWork(eId,jId,fId)
                    dispatch({ type: 'ADD_VALUE_TO_USER', name: "statusAsFreelancer", value: "available" })
                    EmployerRanksRefine(eId,jId,fId,Rating)
                addToIndex(1)
                }}], 
                AllowClosure:false}

             const Ending={Title:<h1>Just thank you</h1>,
                Body:<h1>Thank you for your cooperation and hope we will continue to work together
            </h1>,
              AllowClosure:true}

    
   return([foreword,CommunicationQuestion,PriceQuestion,AdditionsQuestion, WordingQuestion, GeneralQuestion, VerbalDescription,Ending])
// התחלתי להגדיר את שרשרת הסיום
// הרעיון הוא שלכל רכיב יהיה קובץ נפרד 
// (יש קובץ לפס מדרג)
// ובקובץ זה נערוך מערך שיכיל את כל הנתונים לחלונות הקופצים

// כל רכיב במערך יהיה הנתונים לחלון כולל המלל הרכיבים  וה
//  jsx ל Body 
// והמלל לכפתורים והפונקציה שכל כפתור יפעיל

// וכל חלון יפתח את החולן הבא
// בחלון הראשון יהיה גם שמירה של שינוי הסטאטוס של העבודה והפרילנסר
// בכל חלון יהיה קריאה לשמירת הנתונים שיתקבלו בחלון

// יהיה קובץ נוסף שיקבל את המערך ויפעיל בעזרתו את שרשרת החלונות

}
export default FreelancerTerminationChainDefinition