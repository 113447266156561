import React, {useState,useContext} from 'react'
import userContext from '../../context/user-context'
import LevelBar from '../BaseComponent/LevelBar'
import {saveReportingNonWork} from '../../firebase/DbConnection'
import {updateFreelancerRating, FreelancerRanksRefine, jobCompletionApprove, VerifyFinancialAbility, sendBonus} from '../../firebase/DbConnection'
import {addToGroup} from '../../firebase/DbConnection'
import GroupList from '../BaseComponent/GroupList'

// const addToGroup=()=>{alert("add")}


let Reporting={}
const EmployerTerminationChainDefinition=(addToIndex,eId,jId,fId,changeThread)=>{

const {user}=useContext(userContext)


const [level,setLevel]=useState(-1)
const [description,setDescription]=useState()
const [Rating,setRating]=useState(0)
const [GroupName,setGroupName]=useState("all groups")


const onDescriptionChange=(e)=>{
    setDescription(e.target.value)
}
const PerformLevel=(questionName)=>{
if (level<0 || level>10){alert("the level must be between 0 to 10")}
else{
    updateFreelancerRating(eId,jId,fId,questionName,level)
    setLevel(-1)
    addToIndex(1)
    setRating(Rating+level/6)
    console.log("Rating:  ",Rating)
}   } 
const SubmitBonusOrder=()=>{
    if (level<5){alert("minimum bonus - 5$")}
else{
    VerifyFinancialAbility(user.uid,level).then((verification)=>{
        if(verification){
            sendBonus(user.uid,fId,level,jId)
            setLevel(-1)
            changeThread(0,2)
        }else{
            alert("There is not enough money in the account, an additional amount must be deposited to allow this work")
        }
    }).catch((e)=>{alert("error:  "+e)})
}
}

const foreword={
    Title:"Finish Work",
 Body:<h1>The freelancer reported finishing work
 Please check the job and confirm
 </h1>,
 Buttons:[{
ButtonText:"I haven't had a check yet, I'll be back after I check", 
ButtonFunction:()=>{
 changeThread(4,0) 
 
   }},
   {
    ButtonText:"I approve of getting the job", 
    ButtonFunction:()=>{
      addToIndex(1)
      Reporting.state="approve getting"
      
       }},
       {
        ButtonText:"The work was only partially submitted", 
        ButtonFunction:()=>{
            changeThread(1)
            Reporting.state="partially"
           }},
           {
            ButtonText:"I didn't get any work", 
            ButtonFunction:()=>{
              changeThread(1)
              Reporting.state="non work"
               }}], 
AllowClosure:false ,
OpenPopupButtonText:"Job completion reporting" }


const Bonus={
    Title:"Bonus",Body:<div>
        <h1>
Do you want to leave a bonus for a freelancer?  </h1>
    </div>,
    Buttons:[{
    ButtonText:"Not now",
     ButtonFunction:()=>{
       
         addToIndex(1)
   }},
   {
    ButtonText:"Yes, absolutely",
     ButtonFunction:()=>{
       changeThread(5,0)
 
   }}],
      AllowClosure:false}
   


const OpeningQuestionnaire={
    Title:"questionnaire",Body:<div>
        <h1>
We hope you had a good working experience with the freelancer and hope you are happy with his work<br/>
Here's a brief survey of the freelancer's level of work<br/>
Please answer the questions<br/>
Note: your data will not be included until the questionnaire is completed Please do not switch off in the middle<br/>
Please note - the questionnaire is very significant and can greatly affect the continuation of the freelancer's activities, please answer it precisely and seriously<br/>
Only after the reporting is complete - the work will be listed as complete
    </h1>
    </div>,
    Buttons:[{
    ButtonText:"continue",
     ButtonFunction:()=>{
       
         addToIndex(1)
   }}],
      AllowClosure:false}
   


const CommunicationQuestion={
 Title:"Communication with the freelancer",Body:<div>
     <h1>How was the communication with the freelancer?<br/>
 Is the Freelancer available for explanations?<br/>
Is the Freelancer delivering accurate regular reports?<br/>
Is the Freelancer understandable?<br/>
Does he understand the instructions?<br/>
Is he clear?<br/>
Does he speak good and clear English?<br/>
 Did he explain his intention clearly?<br/>
 
 <h6>
        Rating values:
        0- unbearable
        3- Worse
        5- Reasonable
        7- Good
        8-Very good
        9 - Excellent
        10- Excellent ++
        </h6>
 <LevelBar level={level} setLevel={setLevel}/>
 </h1>
 </div>,
 Buttons:[{
 ButtonText:"Send reply and continue",
  ButtonFunction:()=>{
    
      PerformLevel("Communication")
}}],
   AllowClosure:false}


        const ProfessionalismQuestion={Title:<h1>professionalism</h1>,
            Body:<h1>
Has the freelancer shown professionalism in his field?
What is his level of understanding of what he is doing
How would you define him as an expert in his field?
             <h6>
              Rating values:
        0- unbearable
        3- Worse
        5- Reasonable
        7- Good
        8-Very good
        9 - Excellent
        10- Excellent ++
        </h6>
             <LevelBar level={level} setLevel={setLevel}/></h1>,
            Buttons:[{
            ButtonText:"I rank, continue", ButtonFunction:()=>{PerformLevel("Professionalism")}}],
             AllowClosure:false}


             const AccuracyQuestion={Title:<h1>Accuracy</h1>,
                Body:<h1>Accuracy
                Did the freelancer do exactly what was asked for?
                <h6>
                  Rating values:
                1- Really not
                3- Not exactly
                6- What is asked for a reasonable change
                8- Just as I requested
                9-As I requested, only better
                
            </h6>
                 <LevelBar level={level} setLevel={setLevel}/></h1>,
                Buttons:[{
                ButtonText:"I rank, continue", ButtonFunction:()=>{PerformLevel("Accuracy")}}],
                 AllowClosure:false}


            const CourtesyQuestion={Title:<h1>Courtesy and fairness</h1>,
                Body:<h1>
                He was kind and fair in his work?
                How open was he to the demands and changes that did not require him for extra work?
                <h6>
                Rating values:
                3- Not open at all, quarrel with me for any nonsense, even basic and obvious things
                5- reasonably open
                6- Fair
                8- Very open
                9 - Very generous, did a lot more than I initially asked for
        </h6>
            <LevelBar level={level} setLevel={setLevel}/></h1>,
            Buttons:[{
             ButtonText:"I answered, continue", ButtonFunction:()=>{PerformLevel("Courtesy")}}], 
             AllowClosure:false}


             const PunctualityQuestion={Title:<h1>Punctuality</h1>,
                                Body:<h1>Punctuality
                        How far did the freelancer stand during the set times?
                        <h6>
                                Rating values:
                        3- Significantly defective, not at all
                        4- Does not really meet the times - small delays
                        5- Standing at times, but always at the last minute
                        7- Standing in good times
                        8- Long before what I expected
                        9- Make a pre-made exact schedule for each stage and meet it
                                
                        </h6>
                            <LevelBar level={level} setLevel={setLevel}/></h1>,
                            Buttons:[{
                             ButtonText:"I answered, continue", ButtonFunction:()=>{PerformLevel("Punctuality")}}], 
                             AllowClosure:false}


             const GeneralQuestion={Title:<h1>general grade</h1>,
                Body:<h1>Please give a general grade for your collaborative work experience!
                <h6>
                Rating values:
                1- Disaster
                5- Good
                9- Excellent
        </h6>
            <LevelBar level={level} setLevel={setLevel}/></h1>,
            Buttons:[{
             ButtonText:"I answered, continue", ButtonFunction:()=>{PerformLevel("general grade")}}], 
             AllowClosure:false}

             const VerbalDescription={Title:<h1>verbal description</h1>,
                Body:<h1>Please add a verbal description to the job experience you had with the freelancer
                    <textarea
          rows="8" cols="30"
            placeholder="verbal description to your job experience"
            value={description}
            onChange={onDescriptionChange}
          ></textarea>
            </h1>,
            Buttons:[{
             ButtonText:"I answered, continue", ButtonFunction:()=>{
                 if(description){
                    updateFreelancerRating(eId,jId,fId,"verbal description",description)
                    }
                    if(Rating>=6){addToIndex(1)}
                    else{addToIndex(3)}
                
                FreelancerRanksRefine(eId,jId,fId,Rating)
                
                }}], 
                AllowClosure:false}
// ---------------------------------groups
                const AddToGroup={Title:<h1>add to my group</h1>,
                    Body:<h1>Do you want to set the freelancer as a team member?
                    <h6>
                    This means that you can choose to send jobs later only to your team members
            </h6></h1>
                ,
                Buttons:[{
                 ButtonText:"add", ButtonFunction:()=>{
                    addToIndex(1)}},
                 {ButtonText:"no", ButtonFunction:()=>{addToIndex(2)}}], 
                 AllowClosure:false}
    

const ChooseGroupName={Title:<h1>Choose group</h1>,
    Body:<h1>select the group to add the freelancer!
        <h3>
    <GroupList setState={setGroupName}/>
    </h3>
    Or set up a new group: 
    <textarea
        rows="1" cols="20"
            type="text"
            className="title"
            placeholder="enter group name"
            autoFocus
            value={GroupName}
            onChange={(e)=>{setGroupName(e.target.value)}}
          />
    {/* <input type="text" ></input> */}
    </h1>
,
Buttons:[{
 ButtonText:"Approval", ButtonFunction:()=>{addToGroup(user.uid,fId,GroupName)
    addToIndex(1)}}], 
 AllowClosure:false}

// -------------------------------------------
                
             const Ending={Title:<h1>Just thank you</h1>,
                Body:<h1>Thank you for your cooperation and hope we will continue to work together
            </h1>,
            CloseButtons:[{
                ButtonText:"Confirm the details - report!", ButtonFunction:()=>{
                jobCompletionApprove(user.uid,jId,fId)}
            }],
              AllowClosure:false}
// ---------------------------------מכאן והילך זה שאלות למקרים של בעיות בהגשה

const NonWork={
    Title:"Non-work reporting",
 Body:<h1>
 We are sorry to hear,
 Please explain to us exactly what happened,
 Your accurate reporting can prevent the agony of others
 </h1>,
 Buttons:[{
ButtonText:"Continue", 
ButtonFunction:()=>{
  addToIndex(1)
   }}], 
AllowClosure:false ,
 }

const NonWorkReason={
    Title:"why did this happen?",
 Body:<h1>
What is the reason the freelancer did not submit the work?


 </h1>,
 Buttons:[{
ButtonText:"He's just cheating", 
ButtonFunction:()=>{
    addToIndex(1)
    Reporting.Reason="cheating"
   }},
   {
    ButtonText:"He doesn't know how to do it", 
    ButtonFunction:()=>{
        addToIndex(1)
    Reporting.Reason="he doesn't know how to do it"
      
       }},
       {
        ButtonText:"There were changes that depended on me that caused the job to be canceled", 
        ButtonFunction:()=>{
            changeThread(3,0)
    Reporting.Reason="changes that depended on me"
          
           }},
           {
            ButtonText:"He is in the middle he just reported an earlier finish", 
            ButtonFunction:()=>{
    Reporting.Reason="He is in the middle"
              changeThread(2,0)
               }},
               {
                ButtonText:"Other", 
                ButtonFunction:()=>{
    Reporting.Reason="Other"
                  addToIndex(1)
                   }}], 
AllowClosure:false 
 }

const NonWorkDispute={
    Title:"labor dispute",
 Body:<h1>
Are you interested in starting a labor dispute?
(This is the only way to get a refund)

 </h1>,
 Buttons:[{
ButtonText:"Leave it, I just reported", 
ButtonFunction:()=>{
    Reporting.intent="just reported"
    saveReportingNonWork(eId,jId,fId,Reporting)
    addToIndex(2)
   }},
   {
    ButtonText:"Yes I am interested in opening a labor dispute and requesting a refund", 
    ButtonFunction:()=>{
        Reporting.intent="dispute"
        console.log("Reporting:  ",Reporting)
      addToIndex(1)
       }},
       {
        ButtonText:"Leave it, just put him in my blacklist", 
        ButtonFunction:()=>{
            Reporting.intent="blacklist"
            saveReportingNonWork(eId,jId,fId,Reporting)
    addToIndex(2)
           }}], 
AllowClosure:false ,
 }

 
 

 const NonWorkContentsLaborDispute={Title:<h1>Contents of labor dispute</h1>,
    Body:<h1>Please specify what happened
    Please include as much relevant information as possible
    So that we can understand the course of things
    (Try except in irrelevant details)
    <br/>
        <textarea
rows="8" cols="30"
placeholder="Contents of labor dispute"
value={description}
onChange={onDescriptionChange}
></textarea>
</h1>,
Buttons:[{
 ButtonText:"I answered, continue", ButtonFunction:()=>{
    console.log("Reporting:  ",Reporting)
     if(description){
         Reporting.Contents=description
         console.log("Reporting:  ",Reporting)
        }
        saveReportingNonWork(eId,jId,fId,Reporting)
    addToIndex(1)
    }}], 
    AllowClosure:false}

    const NonWorkEnding={
        Title:"labor dispute",
     Body:<h1>
     Thank you for your cooperation and hope you have more successful experiences later
     </h1>,
     
    AllowClosure:true 
     }
//  -----------------------------------

const PartialSubmission={
    Title:"Partial submission",
 Body:<h1>Note!
 The freelancer is required to report - only after finishing the job, and not before!
 Only then can we monitor the working order
 If he reported a termination before he finished
 You don't have to agree to that
 If you trust him - you can continue with him, but we won't be able to supervise the work
 If you don't trust him, you can open a work dispute
 
 </h1>,
 Buttons:[{
ButtonText:"I'll get along with him", 
ButtonFunction:()=>{
    Reporting.PartialSubmission="I'll get along with him"
    saveReportingNonWork(eId,jId,fId,Reporting)
    addToIndex(1)
   }},
   {
    ButtonText:"Disagree", 
    ButtonFunction:()=>{
        Reporting.PartialSubmission="Disagree"
        console.log("Reporting:  ",Reporting)
      changeThread(1,2)
       }}], 
AllowClosure:false ,
 }

 const PartialSubmissionMessage={
    Title:"Partial Submission Message",
 Body:<h1>
Note
The work from here on is not under our control

Successfully
Hope to continue cooperation
 </h1>,
 
AllowClosure:true 
 }

 //  -----------------------------------


const NotCheckMessage={
    Title:"Not Check Message",
 Body:<h1>
Please check the work and submit a report soon
The freelancer expects your reporting -
  That's what promotes him at work

 </h1>,
 
AllowClosure:true 
 }
//  ---------------------------------
const dependedOnMeMessage={
    Title:"Looking ahead ",
 Body:<h1>
We hope these are good reasons
And hope we move on to the next job
If you need help drafting and defining the following work, feel free to contact our support
<br/>
Good luck in all
 </h1>,
 
AllowClosure:true 
 }
// -----------------------------
const GiveBonus={
    Title:"giving a bonus",
 Body:<h1>
 We're glad to hear you're interested in giving a bonus to the freelancer
Getting the bonus is very significant for the freelancer
And provides a positive experience and image enhancement for both parties
<br/><br/>
minimum 5$
<LevelBar level={level} setLevel={setLevel}/>
 </h1>,
 Buttons:[{
ButtonText:"I take it back", 
ButtonFunction:()=>{
    changeThread(0,1)
    setLevel(-1)
   }},
   {
    ButtonText:"With pleasure", 
    ButtonFunction:()=>{
        SubmitBonusOrder()
       
       }}
], 
AllowClosure:false ,
 }
// -----------------------------
    
   return(
       [[foreword, Bonus, OpeningQuestionnaire, CommunicationQuestion, ProfessionalismQuestion, 
        AccuracyQuestion, CourtesyQuestion, PunctualityQuestion, GeneralQuestion,VerbalDescription,AddToGroup,ChooseGroupName,
        Ending],
       [NonWork,NonWorkReason,NonWorkDispute,NonWorkContentsLaborDispute, NonWorkEnding],
       [PartialSubmission,PartialSubmissionMessage],
       [dependedOnMeMessage],
       [NotCheckMessage],
       [GiveBonus]])
    // ,CommunicationQuestion,PriceQuestion,AdditionsQuestion, WordingQuestion, GeneralQuestion, VerbalDescription,Ending])
// התחלתי להגדיר את שרשרת הסיום
// הרעיון הוא שלכל רכיב יהיה קובץ נפרד 
// (יש קובץ לפס מדרג)
// ובקובץ זה נערוך מערך שיכיל את כל הנתונים לחלונות הקופצים

// כל רכיב במערך יהיה הנתונים לחלון כולל המלל הרכיבים  וה
//  jsx ל Body 
// והמלל לכפתורים והפונקציה שכל כפתור יפעיל

// וכל חלון יפתח את החולן הבא
// בחלון הראשון יהיה גם שמירה של שינוי הסטאטוס של העבודה והפרילנסר
// בכל חלון יהיה קריאה לשמירת הנתונים שיתקבלו בחלון

// יהיה קובץ נוסף שיקבל את המערך ויפעיל בעזרתו את שרשרת החלונות

}
export default EmployerTerminationChainDefinition