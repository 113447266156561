import React, {useContext} from 'react'
import userContext from '../../context/user-context'

import Popup from '../Containers/Popup'

import {FreelancerRanksRefine, EmployerRanksRefine, cancelWork} from '../../firebase/DbConnection'

const InabilityFinish=({eId,jId,fId})=>{
    const {dispatch} =useContext(userContext);

    console.log("InabilityFinish>eId:  ",eId)
    console.log("InabilityFinish>jId:  ",jId)
    console.log("InabilityFinish>fId:  ",fId)
const Title="We're sorry to hear that you can't finish the job!"
const Body="Why can't you finish the job?"
const OpenPopupButtonText="Job cancellation"
const CloseButtons=[
{ButtonText:"I accidentally took a job I can't do",
ButtonFunction:()=>{
FreelancerRanksRefine(eId,jId,fId,2)
cancelWork(eId,jId,fId,"can't do")
dispatch({ type: 'ADD_VALUE_TO_USER', name: "statusAsFreelancer", value: "available" })
alert("We hope you succeed next time and try hard not to take jobs you can't finish!")
}
},
{ButtonText:"The employer changed his requirements",
ButtonFunction:()=>{
FreelancerRanksRefine(eId,jId,fId,2)
EmployerRanksRefine(eId,jId,fId,2)
cancelWork(eId,jId,fId,"employer changed requirements")
dispatch({ type: 'ADD_VALUE_TO_USER', name: "statusAsFreelancer", value: "available" })
alert("We took our attention, Thanks for reporting!")
}}]

    return <Popup Title={Title} Body={Body}  CloseButtons={CloseButtons} OpenPopupButtonText={OpenPopupButtonText} />

}
export default InabilityFinish