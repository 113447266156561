import React, { useState, useContext } from 'react';
import userContext from '../../context/user-context'


const UserPicture = ({ width = "60", height = "60", photoURL }) => {

    const { user } = useContext(userContext);
    // console.log("user pic url",user.photoURL)
    return (

        <img
            className="user-image"
            src={photoURL ? photoURL : user.photoURL} width={width} height={height} />

    )
}
export default UserPicture