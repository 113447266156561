import React, { useState } from 'react'
import Popup from '../Containers/Popup'
import StripeButton from './StripeButton'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

const WithdrawalPopup = () => {
  // todo: Radio "checked" do not appear until after changing other values
  // todo: Check that there is enough money in the account
  // todo: Minimum withdrawal $ 100
  // todo: Download commissions
  // todo: Send for execution
  // todo: save account Details
  // todo: get the method option from db

  const [Amount, setAmount] = useState(100)
  const [Method, setMethod] = useState("")
  const [Account, setAccount] = useState("")
  const onAmountChange = (e) => {
    setAmount(e.target.value)
  }
  const onMethodChange = (e) => { setMethod(e.target.value) }

  const PopupTitle = (
    <h1 className="withdrawal-popup-title">
      Withdrawal
    </h1>
  );

  const BodyJsx = (
    <div className="withdrawal-popup-wrapper">
      <h1>For a good job we are glad you take!</h1>
      <h6>Minimum withdrawal 100$ </h6>
      <br />
      <InputGroup className="mb-3 input-group">
        <FormControl
          value={Amount}
          onChange={onAmountChange} />
        <InputGroup.Append>
          <InputGroup.Text>$</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>

      <div className="withdrawal-detail">
        <h2>Select the transfer method:</h2>

        <div className="radio-item">
          <div className="radio-input">
            <input type="radio"
              name="method"
              value="Bank transfer"
              checked={Method === "Bank transfer"}
              onChange={onMethodChange} />
          </div>
          <div className="radio-label">
            Bank transfer
          </div>
        </div>

        <div className="radio-item">
          <div className="radio-input">
            <input type="radio"
              name="method"
              value="xe.com"
              checked={Method === "xe.com"}
              onChange={onMethodChange} />
          </div>
          <div className="radio-label">
            xe.com
          </div>
        </div>

        <div className="radio-item">
          <div className="radio-input">
            <input type="radio"
              name="method"
              value="paypel"
              checked={Method === "paypel"}
              onChange={onMethodChange} />
          </div>
          <div className="radio-label">
            paypel
          </div>
        </div>

        <div className="radio-item">
          <div className="radio-input">
            <input type="radio"
              name="method"
              value="currencytransfer.com"
              checked={Method === "currencytransfer.com"}
              onChange={onMethodChange} />
          </div>
          <div className="radio-label">
            currencytransfer.com
          </div>
        </div>


        <h2>Enter a transfer account code:</h2>
      </div>


      <FormControl
        className="account-input-field"
        value={Account}
        onChange={(e) => { setAccount(e.target.value) }} />

      <h3 className="thanks-note">Thank you, Our pleasure</h3>
    </div>
  )

  return <Popup
    Title={PopupTitle}
    Body={BodyJsx}
    Buttons={[{
      ButtonText: "Withdrawal", ButtonFunction: () => {
        alert(`Pull data:
        sum:${Amount}
        Transfer method:${Method}
        Account code:${Account}
        There was a problem with the transfer, please contact support`)
      }
    }]}
    OpenPopupButtonText="Withdrawal" />
}

export default WithdrawalPopup