import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

const NoUserCarousel = () => {


  return (
    <div className="carousel-wrapper" style={{ 'background-image': "url(/images/hero-slider.png)" }}>
      <Carousel>
        <Carousel.Item>
          <div className="carouselItem"><br />

            <h1>Welcome!</h1> <br />
                waiting for you!<br />
                Connect<br />
                Define whether you are an employer or a freelancer<br />
                And we started working ...
            </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1>How does it work?</h1> <br />
                Employer sends work-<br />
                And that's where he finished his job!<br />
                Now he can sleep peacefully!
            </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
                The work is open to any quality professional<br />
                Whoever wants - catches<br /><br />
            <h1>Quick and professional work, starts immediately on launch day</h1><br />
                Only good and professional freelancers remain in the system
            </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1>The work ended on the best side!</h1>
                everyone ranks his experience of working together<br />
            <h1>Only the best freelancers</h1> remain!
            </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1> As the rating goes up -</h1>
                Projects are getting bigger and more complex ...<br />
                And of course the <h1> profit goes up </h1>too!
            </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carouselItem"><br />
            <h1 style={{ fontSize: 34 }}>
              So what are you waiting for?<br /><br />
                    Let's start realizing dreams!
                </h1>
          </div>
        </Carousel.Item>

      </Carousel>
    </div>
  )
}

export default NoUserCarousel