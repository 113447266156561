import React from 'react'

const LevelBar=({level,setLevel})=>{
const onDataChange=(e)=>{
setLevel(e.target.value)
}
    return(
       
        <div style={{backgroundColor: "#00B1E1"}}>
        <br/>
        <input
        autoFocus={true}
            type="number"
            placeholder="Add level"
            className="level"
            value={level}
            onChange={onDataChange}
          />
          <br/>.
          </div>
    )
}
export default LevelBar