import React, { useState } from 'react'
import { Alert } from 'react-bootstrap';
import UserPicture from '../BaseComponent/UserPicture';
// import {getUserImgById} from '../../firebase/DbConnection'

const ChatText = ({ date, text, sender, uid, otherUserImgUrl }) => {
   const variant = sender === uid ? "primary" : "success"
   const className = sender === uid ? 'my-message' : 'sender-message'
   const spaceClass = sender === uid ? 'my-message-spacer' : ''
   // console.log("OtherUserImgUrl:  ",otherUserImgUrl)
   // const[ otherUserImgUrl,setOtherUserImgUrl ]=useState()
   // if(sender!=uid){getUserImgById(sender).then((photoURL)=>{setOtherUserImgUrl(photoURL)
   // console.log("otherUserImgUrl:  "+otherUserImgUrl)})}
   // console.log("text:  ", text)
   return (
      <div className="chat-message-wrapper">
         <div className={spaceClass} />
         <div className={"chat-message " + className}>
        {sender!=uid&& <div className="user-icon">
               <UserPicture photoURL={otherUserImgUrl} />
            </div>}
            <div className="user-message">
               < Alert variant={variant}>
                  {text}
                  <br />
                  <h6>{date&&new Date(date).toLocaleString()}</h6>
                  
               </ Alert>
            </div>
            {sender === uid&&<div className="user-icon">
               <UserPicture />
            </div>}
         </div>
      </div>
   )
}
export default ChatText