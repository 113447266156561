import React from 'react'
import UserPicture from '../BaseComponent/UserPicture'



const ExperienceSharingItem = ({ ExperienceSharingObject, i }) => {
        return (
                <div className="user-messages">
                        <UserPicture photoURL={ExperienceSharingObject.photoURL} />
                        <div className="user-name">
                                {ExperienceSharingObject.displayName}
                        </div>
                        <h3 className="user-message">
                                {ExperienceSharingObject.text}
                        </h3>
                </div>
        )
}

export default ExperienceSharingItem