import React from 'react';
import { Link } from 'react-router-dom';
import figure from '../images/404Monkey.png';


const NotFoundPage = () => (
  <div className="page-not-found">
    <div className="error-404-page-container">
      <div className="error-404-page-text">
        <div className="error-404-page-content">
          <div className="error-404-heading">
            Oh-ho, page not found...
        </div>
          <div className="error-404-info">
            The page you are looking for might be removed or is temporarily unavailable.
        </div>
          <div className="error-404-button">
            <Link to="/" className="link_404"> Go back Home </Link>
          </div>
        </div>
      </div>
      <div className="error-404-page-image">
        <div className="error-404-image">
          <img src={figure} alt="404" />
        </div>
      </div>
    </div>
  </div >
);

export default NotFoundPage;
